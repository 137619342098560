<template>
  <v-card-text>
    <RadioGroup
      label="Have you ever had Non-IVF Gonadotropins (Pergonal, Metrodin, Repronex, Humegon, Fertinex, Gonal-F, Follistism, Cetrotide, Ganirelix, Lupron) Cycles? (check if yes)"
      v-model="value.haveYouHadCycle"
      :items="defaultRadioItems"
      row
    />
    <TextField
      label="Number of"
      suffix="cycles"
      type="number"
      v-model.number="value.numberOfCycles"
      :disabled="!value.haveYouHadCycle"
      watch-change="true"
    />
    <v-list>
      <v-list-item v-for="(nonIVFCycle, index) in value.cycles" :key="index">
        <v-list-item-content>
          <v-card-subtitle
            class="pl-0 pr-0 pb-8"
            style="font-size: 1.5rem; font-weight: 700; text-align: center"
          >
            Cycle {{ index + 1 }}
          </v-card-subtitle>
          <v-row>
            <v-col cols="12" sm="3">
              <DateField label="Date" v-model="nonIVFCycle.date" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-container>
                <TextField
                  label="Vials/day"
                  type="number"
                  v-model="nonIVFCycle.dose"
                />
              </v-container>
            </v-col>
            <v-col cols="12" sm="3">
              <TextField
                label="Medications"
                v-model="nonIVFCycle.medications"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <Select
                label="Method of Sperm Delivery"
                v-model="nonIVFCycle.method"
                :items="spermDelivMethods"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <TextField
                label="Complications"
                v-model="nonIVFCycle.complications"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-subtitle>Outcomes:</v-card-subtitle>
              <v-checkbox
                dense
                v-model="nonIVFCycle.outcome.pregnancy"
                label="Pregnancy"
              />
              <v-checkbox
                dense
                v-model="nonIVFCycle.outcome.noOvulation"
                label="No Ovulations"
              />
              <v-checkbox
                dense
                v-model="nonIVFCycle.outcome.noPregnancy"
                label="No Pregnancy"
              />
              <v-checkbox
                dense
                v-model="nonIVFCycle.outcome.OHSS"
                label="OHSS"
              />
              <v-checkbox
                dense
                v-model="nonIVFCycle.outcome.birthDefects"
                label="Birth Defects"
              />
              <v-checkbox
                dense
                v-model="nonIVFCycle.outcome.abortion"
                label="Abortion"
              />
              <v-checkbox
                dense
                v-model="nonIVFCycle.outcome.miscarriage"
                label="Miscarriage"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <Select
                label="Delivery Type"
                v-model="nonIVFCycle.deliveryType"
                :items="deliveryTypes"
              />
            </v-col>
          </v-row>
          <v-divider />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script>
import { cyclesMixins, inputRulesMixins } from '../../../mixins';
import _ from 'lodash';

const nonIVFCycleTemplate = () => ({
  date: '',
  dose: null,
  medications: [],
  method: '',
  complications: '',
  outcome: {
    pregnancy: false,
    noOvulation: false,
    noPregnancy: false,
    OHSS: false,
    birthDefects: false,
    abortion: false,
    miscarriage: false
  },
  deliveryType: '',
  deliveryTypeOther: ''
});

export default {
  props: ['value'],
  mixins: [inputRulesMixins, cyclesMixins],
  methods: {
    generateDefault() {
      return nonIVFCycleTemplate();
    }
  }
};
</script>
