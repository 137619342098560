<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="input-field"
        :label="label"
        v-model="dateFormatted"
        :rules="rules"
        :disabled="disabled"
        :prepend-icon="icon ? 'mdi-calendar' : ''"
        :append-icon="icon ? '' : 'mdi-calendar'"
        @click:append="menu = !menu"
        @click:prepend="menu = !menu"
        v-bind="attrs"
        hint="MM/DD/YYYY"
        persistent-hint
        type="tel"
        @change="changeValue(parseDate(dateFormatted))"
        @input="menu = false"
        @blur="date = parseDate(dateFormatted)"
        v-mask="'##/##/####'"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @change="changeValue"
      :active-picker.sync="activePicker"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['label', 'value', 'rules', 'disabled', 'icon'],
  data() {
    return {
      menu: false,
      show: false,
      activePicker: 'MONTH',
      date: '',
      dateFormatted: ''
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = this.picker || 'YEAR'), 150);
    },
    value: function (v) {
      this.date = v;
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    changeValue(v) {
      this.$refs.menu.save(v);
      this.menu = false;
      this.date = v;
      this.$emit('input', v);
    }
  },
  mounted() {
    if (!this.value) return;
    if (this.value === '') return;
    this.date = new Date(this.value).toISOString().substr(0, 10);
  }
};
</script>
