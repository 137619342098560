<template>
  <v-checkbox
    :class="{
      classList: true,
      'input-field': true
    }"
    :label="label"
    v-model="data"
    :rules="rules"
    :disabled="disabled"
    @change="(v) => changeValue(v)"
  />
</template>

<script>
export default {
  props: ['classList', 'label', 'value', 'rules', 'disabled'],
  data() {
    return {
      data: false
    };
  },
  methods: {
    changeValue(v) {
      this.$emit('input', v);
    }
  },
  watch: {
    value: function (v) {
      this.data = v;
    }
  },
  mounted() {
    this.data = this.value;
  }
};
</script>

<style scoped>
.input-field {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
