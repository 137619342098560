<template>
  <v-card-text>
    <RadioGroup
      label="Have you ever had In-Vitro Fertilization (IVF) Cycles? (check if yes)"
      v-model="value.haveYouHadCycle"
      :items="defaultRadioItems"
      row
    />
    <TextField
      label="Number of"
      suffix="cycles"
      type="number"
      v-model.number="value.numberOfCycles"
      :disabled="!value.haveYouHadCycle"
      watch-change="true"
    />
    <v-list>
      <v-list-item v-for="(IVFCycle, index) in value.cycles" :key="index">
        <v-list-item-content>
          <v-card-subtitle
            class="pl-0 pr-0 pb-8"
            style="font-size: 1.5rem; font-weight: 700; text-align: center"
          >
            Cycle {{ index + 1 }}
          </v-card-subtitle>
          <v-row>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <DateField label="Date" v-model="IVFCycle.date" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <TextField label="Egg Source" v-model="IVFCycle.eggSource" />
                </v-col>
              </v-row>

              <v-col>
                <TextField label="Medications" v-model="IVFCycle.medications" />
              </v-col>
              <v-col>
                <TextField
                  label="Dose"
                  suffix="Vials/day"
                  type="number"
                  v-model="IVFCycle.dose"
                />
              </v-col>
              <v-col>
                <Checkbox label="ICSI" v-model="IVFCycle.icsi" />
              </v-col>
            </v-container>
          </v-row>
          <v-row>
            <v-container>
              <v-col cols="12" sm="3">
                <TextField
                  label="# Eggs"
                  suffix="eggs"
                  type="number"
                  v-model="IVFCycle.eggsAmount"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <TextField
                  label="Eggs Fertilized"
                  suffix="eggs"
                  type="number"
                  v-model="IVFCycle.eggsFertilized"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <TextField label="Tran Day" v-model="IVFCycle.tranDay" />
              </v-col>
              <v-col cols="12" sm="3">
                <TextField
                  label="# Tran"
                  type="number"
                  v-model="IVFCycle.tranAmount"
                />
              </v-col>
            </v-container>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <TextField
                label="# Frozen"
                type="number"
                v-model="IVFCycle.frozenAmount"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-subtitle>Outcomes:</v-card-subtitle>
              <v-checkbox
                dense
                v-model="IVFCycle.outcome.pregnancy"
                label="Pregnancy"
              />
              <v-checkbox
                dense
                v-model="IVFCycle.outcome.noOvulation"
                label="No Ovulations"
              />
              <v-checkbox
                dense
                v-model="IVFCycle.outcome.noPregnancy"
                label="No Pregnancy"
              />
              <v-checkbox dense v-model="IVFCycle.outcome.OHSS" label="OHSS" />
              <v-checkbox
                dense
                v-model="IVFCycle.outcome.birthDefects"
                label="Birth Defects"
              />
              <v-checkbox
                dense
                v-model="IVFCycle.outcome.abortion"
                label="Abortion"
              />
              <v-checkbox
                dense
                v-model="IVFCycle.outcome.miscarriage"
                label="Miscarriage"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <Select
                label="Delivery Type"
                v-model="IVFCycle.deliveryType"
                :items="deliveryTypes"
              />
            </v-col>
          </v-row>
          <v-divider />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script>
import { cyclesMixins, inputRulesMixins } from '../../../mixins';

const IVFCycleTemplate = () => ({
  date: '',
  eggSource: '',
  medications: [],
  dose: null,
  icsi: false,
  eggsAmount: null,
  eggsFertilized: null,
  tranDay: '',
  tranAmount: null,
  frozenAmount: null,
  outcome: {
    pregnancy: false,
    noOvulation: false,
    noPregnancy: false,
    OHSS: false,
    birthDefects: false,
    abortion: false,
    miscarriage: false
  },
  deliveryType: ''
});

export default {
  props: ['value'],
  mixins: [inputRulesMixins, cyclesMixins],
  methods: {
    generateDefault() {
      return IVFCycleTemplate();
    }
  }
};
</script>
