<template>
  <v-col>
    <v-row>
      <v-col cols="12" md="5">
        <Checkbox
          classList="finding-checkbox"
          :label="checkBoxLabel"
          v-model="value.checked"
        />
      </v-col>
      <template v-if="value.checked">
        <v-col cols="12" md="4">
          <DateField label="Date" v-model="value.date" />
        </v-col>
        <v-col cols="12" md="3">
          <TextField :label="textFieldLabel" v-model="textFieldValue" />
        </v-col>
      </template>
    </v-row>
  </v-col>
</template>
<script>
import InputMixins from '../../../mixins/inputRulesMixins';

export default {
  props: ['value', 'checkBoxLabel', 'textFieldLabel'],
  mixins: [InputMixins],
  computed: {
    textFieldValue: {
      get() {
        return this.value[this.textFieldKey];
      },
      set(val) {
        this.value[this.textFieldKey] = val;
        this.$emit('input', this.value);
      }
    },
    textFieldKey() {
      return this.textFieldLabel && this.textFieldLabel === 'Findings'
        ? 'findings'
        : 'results';
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .finding-checkbox {
    margin-left: 0 !important;
    margin-right: 0;
  }
}
</style>
