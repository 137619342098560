<template>
  <v-container>
    <RadioGroup
      v-model="value.hadSurgeries"
      :items="defaultRadioItems"
      label="Have you had any surgeries? (Excluding Laparoscopy and Hysteroscopy)"
    />
    <template v-if="value.hadSurgeries">
      <v-card-title style="background: #1f6070; color: white; width: 100%">
        Surgeries:
      </v-card-title>
      <v-card
        v-for="(surgery, index) in surgeryItems"
        :key="index"
        width="100%"
      >
        <v-card-text>
          <v-row align-self="center" style="align-items: center">
            <v-col cols="12" md="4">
              <v-card-subtitle class="pr-0 pl-0">Surgery:</v-card-subtitle>
              <TextField v-model="surgery.surgery" />
            </v-col>

            <v-col cols="12" md="4">
              <v-card-subtitle class="pr-0 pl-0">Indication:</v-card-subtitle>
              <TextField v-model="surgery.indication"></TextField>
            </v-col>

            <v-col cols="12" md="4">
              <v-card-subtitle class="pr-0 pl-0">Date:</v-card-subtitle>
              <DateField v-model="surgery.date" />
            </v-col>

            <v-col cols="12" md="4">
              <RadioGroup
                v-model="surgery.complications"
                label="Complications?"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-card-subtitle class="pr-0 pl-0">Findings:</v-card-subtitle>
              <TextField v-model="surgery.findings"></TextField>
            </v-col>
          </v-row>

          <v-row v-if="index + 1 === surgeryItems.length">
            <v-col cols="12" style="display: flex; justify-content: center">
              <v-icon class="mr-2" x-large @click="addSurgery(surgery, index)">
                mdi-plus
              </v-icon>

              <v-icon class="ml-2" x-large @click="removeSurgery(index)">
                mdi-minus
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import inputRulesMixins from '../../../mixins/inputRulesMixins.js';

export default {
  props: ['value'],
  mixins: [inputRulesMixins],
  data: () => ({
    surgeryItems: [
      {
        date: null,
        indication: '',
        surgery: '',
        findings: '',
        complications: null
      }
    ],
    hadSurgeries: false
  }),
  watch: {
    hadSurgeries: {
      deep: true,
      handler(val) {
        this.$emit('input', { ...this.value, hadSurgeries: val });
      }
    }
  },
  methods: {
    addSurgery(surgery, index) {
      var blankSurgery = {
        date: null,
        indication: '',
        surgery: '',
        findings: '',
        complications: null
      };
      if (surgery.surgery === '') {
        return;
      } else {
        this.surgeryItems[index] = surgery;
        this.surgeryItems.push(blankSurgery);
        this.updateSurgeriesHad();
      }
    },
    removeSurgery(index) {
      if (this.surgeryItems.length === 1) {
        return;
      }
      this.surgeryItems.splice(index, 1);
      this.updateSurgeriesHad();
    },
    updateSurgeriesHad() {
      var copyList = [...this.surgeryItems];
      var last = copyList.length - 1;
      if (copyList[last].surgery === '') {
        copyList.splice(last, 1);
      }
      this.$emit('input', {
        ...this.value,
        surgeriesHad: [...copyList]
      });
    }
  }
};
</script>
