<template>
  <v-select
    class="input-field"
    :label="label"
    v-model="data"
    :items="items"
    :rules="rules"
    :multiple="multiple"
    :disabled="disabled"
    menu-props="auto"
    @change="(v) => changeValue(v)"
  />
</template>

<script>
export default {
  props: ['label', 'value', 'rules', 'items', 'multiple', 'disabled'],

  data() {
    return {
      data: ''
    };
  },

  methods: {
    changeValue(v) {
      this.data = v;
      this.$emit('input', v);
    }
  },

  watch: {
    value: function (v) {
      this.data = v;
    }
  },

  created() {
    this.data = this.value;
  }
};
</script>

<style scoped>
.input-field {
  margin: 15px 0px 15px 0px;
}
</style>
