export const toUploadFormat = (file, noCompress = false) =>
  noCompress ? toDocumentFormat(file) : toImageFormat(file);
const toImageFormat = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = () => {
      //creating blob
      const blob = new Blob([reader.result]);
      window.URL = window.URL || window.webkitURL;

      const blobUrl = window.URL.createObjectURL(blob);
      //helper image
      let image = new Image();
      image.src = blobUrl;

      image.onload = () => {
        const resized = compressImage(image);

        resolve(resized);
      };
      image.onerror = (err) => reject(err);
    };
    reader.onerror = () => reject(reader.error);
  });

const toDocumentFormat = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
  });

const compressImage = (image) => {
  const canvas = document.createElement('canvas');

  canvas.width = image.width;
  canvas.height = image.height;

  let ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, image.width, image.height);

  return canvas.toDataURL('image/jpeg', 0.1);
};
