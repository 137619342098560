<template>
  <v-card-text>
    <RadioGroup
      label="Have you ever had a Non-medicated IUI or Intercourse or Clomiphene Citrate (Clomid, Serophene) Cycle?"
      v-model="value.haveYouHadCycle"
      :items="defaultRadioItems"
      row
    />
    <v-col>
      <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
        If yes, please indicate the number of treatment cycles and fill out the
        following rows for each cycle:
      </v-card-subtitle>
      <TextField
        label="Enter the number"
        suffix="cycles"
        type="number"
        v-model.number="value.numberOfCycles"
        :disabled="!value.haveYouHadCycle"
        watch-change="true"
      />
    </v-col>
    <v-list>
      <v-list-item
        v-for="(clomipheneCitrateCycle, index) in value.cycles"
        :key="index"
        class="pr-1 pl-1"
      >
        <v-list-item-content>
          <v-divider class="mb-8" />
          <v-card-subtitle
            class="pl-0 pr-0 pb-8"
            style="font-size: 1.5rem; font-weight: 700; text-align: center"
          >
            Cycle {{ index + 1 }}
          </v-card-subtitle>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="12" class="pt-0">
                  <Select
                    label="Delivery Type"
                    v-model="clomipheneCitrateCycle.deliveryType"
                    :items="deliveryTypes"
                  />
                </v-col>
                <v-col cols="12" md="12" class="pt-0">
                  <v-card-subtitle class="pt-0">Outcomes:</v-card-subtitle>
                  <v-checkbox
                    dense
                    v-model="clomipheneCitrateCycle.outcome.pregnancy"
                    label="Pregnancy"
                  />
                  <v-checkbox
                    dense
                    v-model="clomipheneCitrateCycle.outcome.noOvulation"
                    label="No Ovulations"
                  />
                  <v-checkbox
                    dense
                    v-model="clomipheneCitrateCycle.outcome.noPregnancy"
                    label="No Pregnancy"
                  />
                  <v-checkbox
                    dense
                    v-model="clomipheneCitrateCycle.outcome.OHSS"
                    label="OHSS"
                  />
                  <v-checkbox
                    dense
                    v-model="clomipheneCitrateCycle.outcome.birthDefects"
                    label="Birth Defects"
                  />
                  <v-checkbox
                    dense
                    v-model="clomipheneCitrateCycle.outcome.abortion"
                    label="Abortion"
                  />
                  <v-checkbox
                    dense
                    v-model="clomipheneCitrateCycle.outcome.miscarriage"
                    label="Miscarriage"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="12">
                  <DateField
                    label="Date"
                    v-model="clomipheneCitrateCycle.date"
                  />
                </v-col>
                <v-col cols="12" md="11">
                  <v-container>
                    <TextField
                      label="Dose"
                      suffix="mg"
                      type="number"
                      v-model="clomipheneCitrateCycle.dose"
                    />
                  </v-container>
                </v-col>
                <v-col cols="12" md="12">
                  <Select
                    label="Monitoring"
                    v-model="clomipheneCitrateCycle.monitoring"
                    :items="monitoringOptions"
                    :multiple="true"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <Select
                    label="Method of Sperm Delivery"
                    v-model="clomipheneCitrateCycle.method"
                    :items="spermDelivMethods"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-container>
                    <TextField
                      label="Complications"
                      v-model="clomipheneCitrateCycle.complications"
                    />
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script>
import { inputRulesMixins, cyclesMixins } from '../../../mixins/';
import _ from 'lodash';

const clomipheneCitrateCycleTemplate = () => ({
  date: '',
  dose: null,
  monitoring: '',
  method: '',
  complications: '',
  outcome: {
    pregnancy: false,
    noOvulation: false,
    noPregnancy: false,
    OHSS: false,
    birthDefects: false,
    abortion: false,
    miscarriage: false
  },
  deliveryType: ''
});

export default {
  props: ['value'],
  mixins: [inputRulesMixins, cyclesMixins],
  methods: {
    //Cycle mixin requires generateDefault function in order to work correctly
    generateDefault() {
      return clomipheneCitrateCycleTemplate();
    }
  }
};
</script>
