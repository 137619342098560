<template>
  <v-card flat>
    <v-card-title class="justify-center">
      <h3>Environmental Factor</h3>
    </v-card-title>
    <v-card-text>
      <RadioGroup
        row
        label="Do you currently use any nicotine products (cigarettes, cigars, smokeless tobacco, vaping, etc)?"
        v-model="value.currentlyUseNicotine"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="In the past, have you ever used any nicotine products (cigarettes, cigars, smokeless tobacco, vaping, etc)?"
        v-model="value.haveUsedNicotine"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Do you drink Alcohol?"
        v-model="value.alcohol.drinksAlcohol"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-container v-if="showAlcohol">
        <v-card-subtitle>
          How do you describe your drinking habits?
        </v-card-subtitle>
        <v-row>
          <RadioGroup
            row
            v-model="value.alcohol.drinkingHabit"
            :items="drinkingHabits"
            :rules="nonNullRule"
          />
          <TextField
            type="number"
            :suffix="drinkingHabitSuffix"
            v-model="value.alcohol.amount"
          />
        </v-row>
      </v-container>
      <RadioGroup
        row
        label="Have you ever had issues with alcohol dependency/abuse?"
        v-model="value.alcoholDependency"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Do you consume caffeinated beverages?"
        v-model="value.caffeinatedBeverages.consumeCaffeinatedBeverages"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-container v-if="showCaffeinated">
        <v-card-subtitle>If yes, how much?</v-card-subtitle>
        <RadioGroup
          row
          v-model="value.caffeinatedBeverages.amount"
          :items="caffeinatedDrinkingAmounts"
          :rules="nonNullRule"
          styles="width: 34%"
        />
      </v-container>
      <RadioGroup
        row
        label='Do you use "recreational" drugs?'
        v-model="value.useRecreationalDrugs"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Do you take baths or use hot tubs?"
        v-model="value.takeBaths"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a high fever recently?"
        v-model="value.highFeverRecently"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you been exposed to any chemicals?"
        v-model="value.chemicalExposure"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="In the past six months have you traveled to any areas where Zika virus has been present or do you plan to travel to one of these areas in the future?"
        v-model="value.zikaVirus"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import InputMixins from '../../../mixins/inputRulesMixins';

export default {
  mixins: [InputMixins],
  props: ['value'],
  data: () => ({
    drinkingHabits: ['Socially', 'Daily', 'Alcoholic'],
    caffeinatedDrinkingAmounts: [
      '1-2 per day',
      '3-4 per day',
      'More than 5 per day',
      '1-2 per week',
      '3-4 per week',
      'More than 5 per week',
      '1-2 per month',
      '3-4 per month',
      'More than 5 per month'
    ]
  }),
  computed: {
    drinkingHabitSuffix() {
      return this.value.alcohol.drinkingHabit === 'Daily'
        ? 'Drinks Per Day'
        : 'Drinks Per Week';
    },
    showAlcohol() {
      return this.value.alcohol.drinksAlcohol?.value;
    },
    showCaffeinated() {
      return this.value.caffeinatedBeverages.consumeCaffeinatedBeverages?.value;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        // value reset
        if (
          !val.alcohol.drinksAlcohol?.value &&
          (val.alcohol.amount !== null || val.alcohol.drinkingHabit !== null)
        ) {
          this.$emit('input', {
            ...this.value,
            alcohol: {
              ...this.value.alcohol,
              amount: null,
              drinkingHabit: null
            }
          });
        }
        //value reset
        if (
          !val.caffeinatedBeverages.consumeCaffeinatedBeverages?.value &&
          val.caffeinatedBeverages.amount !== null
        ) {
          this.$emit('input', {
            ...this.value,
            caffeinatedBeverages: {
              ...this.value.caffeinatedBeverages,
              amount: null
            }
          });
        }
      }
    }
  }
};
</script>
