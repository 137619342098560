<template>
  <v-row style="display: flex; flex-direction: column">
    <v-card-subtitle>
      Have you ever had any of the following procedures performed?
    </v-card-subtitle>
    <DateFinding
      v-for="procedureLabel in procedureLabels"
      :key="procedureLabel.key"
      :checkBoxLabel="procedureLabel.label"
      v-model="value[procedureLabel.key]"
      :textFieldLabel="textFieldLabel"
    />
  </v-row>
</template>

<script>
import InputMixins from '../../../mixins/inputRulesMixins';
import DateFinding from './DateFinding.vue';

export default {
  props: ['value'],
  mixins: [InputMixins],
  components: { DateFinding },
  data: () => ({
    procedureLabels: [
      {
        label: 'Colposcopy (Follow up after an abnormal pap)',
        key: 'colposcopy'
      },
      {
        label: 'Cryosurgery (destroy abnormal tissue by freezing)',
        key: 'cryosurgery'
      },
      {
        label: 'Cone Biopsy (removal of tissue from the cevix canal)',
        key: 'coneBiopsy'
      },
      { label: 'Laser Treatment', key: 'laserTreatment' },
      {
        label:
          'LEEP Procedure (Procedure done for abnormal cells on the cervix)',
        key: 'LEEP'
      }
    ],
    textFieldLabel: 'Findings'
  })
};
</script>
