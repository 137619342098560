<template>
  <v-row>
    <v-text-field
      class="input-field"
      :label="label"
      :prefix="prefix"
      :suffix="suffix"
      v-model="data"
      :type="type"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      @change="(v) => changeValue(v)"
      @input="(v) => (watchChange ? changeValue(v) : null)"
    />
  </v-row>
</template>

<script>
export default {
  props: [
    'label',
    'prefix',
    'suffix',
    'value',
    'type',
    'rules',
    'disabled',
    'readonly',
    'watch-change'
  ],

  data() {
    return {
      data: ''
    };
  },

  methods: {
    changeValue(v) {
      const val = this.type === 'number' ? Number(v) : v;
      this.data = val;
      this.$emit('input', val);
    }
  },
  watch: {
    value: function (v) {
      this.data = v;
    }
  },
  mounted() {
    this.data = this.value ?? '';
  }
};
</script>

<style scoped>
.input-field {
  margin: 15px 10px;
}
</style>
