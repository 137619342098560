<template>
  <div style="display: contents" v-if="!intakeLoading">
    <v-container v-if="formState === 'not created'">
      <v-card class="pr-6 pl-6 pb-6 pt-4">
        <v-card-title>New Patient Intake Form</v-card-title>
        <v-card-subtitle>
          To get started, we need to know more about you such as family medical
          history and previous fertility treatments. This will help our
          physicians create a personalized treatment protcol for your specific
          history and needs. Select a form below based on your biological sex at
          birth. This process can take up to 45 minutes. Your answers are saved
          as you go to allow you to return as needed.
        </v-card-subtitle>
        <v-card-text>
          <v-select
            v-model="select"
            :items="items"
            menu-props="auto"
            label="Form Type"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="intakeLoading"
            :disabled="select === null"
            @click="createForm(select)"
          >
            Start Form
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <v-container v-else-if="initialFormData" fluid>
      <component
        @deleteForm="deleteForm(initialFormData)"
        @finishForm="finishForm"
        :is="formComponent"
        :value="initialFormData"
        ref="form"
      />
    </v-container>
    <v-container v-else>
      <v-card>
        <v-card-title>Form submitted!</v-card-title>
        <v-card-text>
          Thank you for completing your patient intake form. Your information
          has been successfully submitted.
        </v-card-text>
        <v-footer v-if="isNotProd" class="d-flex justify-end">
          <v-spacer />
          <!-- <v-btn color="error" @click="deleteForm(initialFormData)">
            Restart ({{ appEnv }})
          </v-btn> -->
        </v-footer>
      </v-card>
    </v-container>
  </div>
  <div v-else>
    <div class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
  </div>
</template>

<script>
import FemaleForm from '../components/forms/FemaleForm.vue';
import MaleForm from '../components/forms/MaleForm.vue';
import { api, auth } from '../sharedPlugin';

export default {
  components: { FemaleForm, MaleForm },
  data: () => ({
    select: null,
    items: [
      { text: 'Female Information', value: 'FemaleForm' },
      { text: 'Male Information', value: 'MaleForm' }
    ]
  }),
  async mounted() {
    const accessToken = this.$route?.query?.token;
    if (accessToken) {
      this.logged(accessToken);
    }
    await this.$store.dispatch('auth/initializeIntake');
  },
  computed: {
    ...auth.mapComputed(['formState', 'intakeLoading', 'initialFormData']),
    formComponent() {
      return this.initialFormData?.formData?.formType === 'MaleForm'
        ? MaleForm
        : FemaleForm;
    },
    isNotProd() {
      return process.env.VUE_APP_ENV !== 'production';
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    }
  },
  methods: {
    ...auth.mapMethods(['createForm', 'deleteForm', 'logged']),
    submit() {
      if (this.select !== null) this.createForm(this.select);
    },
    finishForm() {
      this.$refs.form.validate();
    }
  }
};
</script>
