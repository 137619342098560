<template>
  <v-card flat>
    <v-card-title class="justify-center">
      <h3>Medical History</h3>
    </v-card-title>
    <v-card-text>
      <RadioGroup
        row
        label="Do you have any long-standing medical conditions?"
        v-model="value.hasLongstandingConditions"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Are you currently taking any medications?"
        v-model="value.medications.currentlyTakingMeds"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-container v-if="currentlyTakingMeds">
        <div>If yes please list:</div>
        <v-data-table
          id="tableMedications"
          :headers="medicationHeaders"
          :items="medicationItems"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.medication`]="{ item }">
            <TextField
              v-model="item.medication"
              label="Enter the medication here"
            />
          </template>
          <template v-slot:[`item.comments`]="{ item }">
            <TextField v-model="item.comments" label="Enter the reason here" />
          </template>
        </v-data-table>
        <Checkbox
          label="Please check here, if you are taking more than five medications."
          v-model="takingMoreMeds"
        />
      </v-container>
      <RadioGroup
        row
        label="Do you use herbal remedies, vitamins or over the counter medications?"
        v-model="value.herbalRemedies.usesRemedies"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-container v-if="value.herbalRemedies.usesRemedies">
        <TextField
          label="If yes, what?"
          v-model="value.herbalRemedies.herbalRemedy"
          :rules="genericInputRules"
        />
        <TextField
          label="How much per day?"
          type="number"
          v-model="value.herbalRemedies.perDay"
          :rules="genericInputRules"
        />
      </v-container>
      <RadioGroup
        row
        label="Are you on a special diet?"
        v-model="value.specialDiet"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had any surgeries?"
        v-model="value.surgeries.hadSurgeries"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-container v-if="hadSurgeries">
        <div>If yes please list:</div>
        <v-data-table
          id="tableSurgeries"
          :headers="surgeryHeaders"
          :items="surgeryItems"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.date`]="{ item }">
            <DateField label="Surgery date" v-model="item.date" />
          </template>
          <template v-slot:[`item.indication`]="{ item }">
            <TextField
              v-model="item.indication"
              label="Enter the indication here"
            />
          </template>
          <template v-slot:[`item.surgery`]="{ item }">
            <TextField v-model="item.surgery" label="Enter the surgery here" />
          </template>
          <template v-slot:[`item.findings`]="{ item }">
            <TextField
              v-model="item.fidnings"
              label="Enter the findings here"
            />
          </template>
          <template v-slot:[`item.complications`]="{ item }">
            <RadioGroup
              row
              v-model="item.complications"
              :items="defaultRadioItems"
            />
          </template>
        </v-data-table>
        <Checkbox
          label="Please check here, if you have had more than five surgeries."
          v-model="hadMoreSurgeries"
        />
      </v-container>
      <RadioGroup
        row
        label="Have you had any exposure to or have been treated for any sexually transmitted disease or infection?"
        v-model="value.hadStd"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Are you allergic to or have had any adverse reaction to any medications, foods, or other environmental factors?"
        v-model="value.allergicToMeds"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had Mumps?"
        v-model="value.hadMumps"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Do you have any difficulty with erections?"
        v-model="value.difficultyWithErection"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Do you have any difficulty with ejaculation?"
        v-model="value.difficultyWithEjaculation"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever been unable to impregnate a female partner?"
        v-model="value.unableToImpregnate"
        :items="[...defaultRadioItems, 'Not Applicable']"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you been a sperm donor?"
        v-model="value.beenSpermDonor"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever taken testosterone, pre-testosterone supplements, hormone treatments and other steroids?"
        v-model="value.takenSteroids"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever had a vasectomy?"
        v-model="value.hadVasectomy"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever had surgery to testicles?"
        v-model="value.testicalSurgery"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had undescended testicles?"
        v-model="value.undescendedTesticals"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had trauma to the testicles?"
        v-model="value.testicalTrauma"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had painful swelling of the testicles?"
        v-model="value.testicalSwelling"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had painful torsion(twisting) of the testicles?"
        v-model="value.testicalTorsion"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever had white blood cells in your semen?"
        v-model="value.whiteBloodCellsInSemen"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever had a prostate infection?"
        v-model="value.prostateInfection"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import InputMixins from '../../../mixins/inputRulesMixins';

const defaultMedicationsArray = () => [
  { medication: '', comments: '' },
  { medication: '', comments: '' },
  { medication: '', comments: '' },
  { medication: '', comments: '' },
  { medication: '', comments: '' }
];

const defaultSurgeryArray = () => [
  { date: '', indication: '', surgery: '', findings: '', complications: null },
  { date: '', indication: '', surgery: '', findings: '', complications: null },
  { date: '', indication: '', surgery: '', findings: '', complications: null },
  { date: '', indication: '', surgery: '', findings: '', complications: null },
  { date: '', indication: '', surgery: '', findings: '', complications: null }
];

export default {
  mixins: [InputMixins],
  props: ['value'],
  data: () => ({
    takingMoreMeds: false,
    hadMoreSurgeries: false,
    medicationHeaders: [
      { text: 'Medications', value: 'medication', divider: true },
      { text: 'Reason/Comments', value: 'comments', divider: true }
    ],
    medicationItems: [],
    surgeryHeaders: [
      { text: 'Date', value: 'date', divider: true },
      { text: 'Indication', value: 'indication', divider: true },
      { text: 'Surgery', value: 'surgery', divider: true },
      { text: 'Findings', value: 'findings', divider: true },
      { text: 'Complications', value: 'complications', divider: true }
    ],
    surgeryItems: []
  }),
  computed: {
    currentlyTakingMeds() {
      return this.value.medications.currentlyTakingMeds;
    },
    hadSurgeries() {
      return this.value.surgeries.hadSurgeries;
    }
  },
  watch: {
    currentlyTakingMeds(val) {
      this.medicationItems = val ? defaultMedicationsArray() : [];
    },
    hadSurgeries(val) {
      this.surgeryItems = val ? defaultSurgeryArray() : [];
    },
    takingMoreMeds(takingMoreMeds) {
      takingMoreMeds
        ? this.medicationItems.push(...defaultMedicationsArray())
        : (this.medicationItems = this.medicationItems.slice(0, 5));
    },
    hadMoreSurgeries(hadMoreSurgeries) {
      hadMoreSurgeries
        ? this.surgeryItems.push(...defaultSurgeryArray())
        : (this.surgeryItems = this.surgeryItems.slice(0, 5));
    },
    surgeryItems: {
      deep: true,
      handler(val) {
        this.$emit('input', {
          ...this.value,
          surgeries: {
            ...this.value.surgeries,
            surgeriesHad: val
          }
        });
      }
    },
    medicationItems: {
      deep: true,
      handler(val) {
        this.$emit('input', {
          ...this.value,
          medications: {
            ...this.value.medications,
            medicationsTaken: val
          }
        });
      }
    }
  },
  mounted() {
    this.medicationItems = this.value.medications.medicationsTaken;
    this.surgeryItems = this.value.surgeries.surgeriesHad;
  }
};
</script>

<style>
@media (max-width: 768px) {
  .v-data-table--mobile {
    margin-top: 10px;
  }
  #tableMedications .v-data-table-header-mobile {
    display: none;
  }
  #tableMedications tr.v-data-table__mobile-table-row td {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 0;
    margin-bottom: 10px;
  }
  #tableMedications tr.v-data-table__mobile-table-row {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  #tableMedications tr.v-data-table__mobile-table-row:first-child {
    border-top: none;
  }
  #tableMedications tr.v-data-table__mobile-table-row td:last-child {
    padding-bottom: 10px;
    border: none;
  }
  #tableMedications tr.v-data-table__mobile-table-row {
    padding-top: 15px;
    padding-bottom: 5px;
  }
  #tableMedications .v-data-table__mobile-row__cell {
    width: 80%;
  }

  #tableSurgeries .v-data-table-header-mobile {
    display: none;
  }
  #tableSurgeries tr.v-data-table__mobile-table-row td {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 0;
    margin-bottom: 10px;
  }
  #tableSurgeries tr.v-data-table__mobile-table-row {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  #tableSurgeries tr.v-data-table__mobile-table-row:first-child {
    border-top: none;
  }
  #tableSurgeries tr.v-data-table__mobile-table-row td:last-child {
    padding-bottom: 10px;
    border: none;
  }
  #tableSurgeries tr.v-data-table__mobile-table-row {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #tableSurgeries .v-data-table__mobile-row__cell {
    width: 80%;
  }
}
</style>
