<template>
  <v-row>
    <v-card-subtitle v-if="label">{{ label }}</v-card-subtitle>
    <v-card-text>
      <v-radio-group
        v-model="radioGroupVal"
        @change="updateProp"
        :row="row"
        :rules="[...rules]"
        :style="styles"
      >
        <v-radio
          v-for="(item, index) in items"
          :key="index"
          :label="item.text ? item.text : item"
          :value="item"
        />
      </v-radio-group>
    </v-card-text>
  </v-row>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    value: [String, Number, Boolean, Object],
    row: {
      type: Boolean,
      default: false
    },
    label: String,
    styles: String,
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    radioGroupVal: null
  }),
  methods: {
    updateProp(val) {
      this.$emit('input', val.value ?? val);
    }
  },
  watch: {
    value(val) {
      if (this.radioGroupVal !== null) {
        this.radioGroupVal = this.items.find((el) => {
          if (typeof val === 'boolean') {
            return `${val}` === `${el.value}`;
          } else if (typeof val === 'number') {
            return val === el.value;
          } else if (el?.text && val?.text) {
            return el.text === val.text;
          }
          return el === val;
        });
      }
    }
  },
  mounted() {
    // setting radioGroupVal to just passed in value was having issue's lining up object reference so using .find to grab proper object reference
    if (this.value !== null) {
      this.radioGroupVal =
        this.value !== null &&
        this.items.find((el) => {
          if (typeof this.value === 'boolean') {
            return `${this.value}` === `${el.value}`;
          } else if (typeof this.value === 'number') {
            return this.value === el.value;
          } else if (el?.text && this.value?.text) {
            return el.text === this.value.text;
          }
          return el === this.value;
        });
    }
  }
};
</script>
