<template>
  <v-card flat>
    <v-card-title class="justify-center">
      <h3>Infertility Tests</h3>
    </v-card-title>
    <v-card-text>
      <RadioGroup
        row
        label="Have you or your past or present female partner(s) ever participated in fertility treatment?"
        v-model="value.hadFertilityTreatment"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever had fertility medications (hormone, etc.)?"
        v-model="value.hadFertilityMedication"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a semen analysis?"
        v-model="value.hadSemenAnalysis"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you ever been seen by a urologist or male infertility expert?"
        v-model="value.seenUrologistOrInferlilityExpert"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had an surgery to correct a sperm problem?"
        v-model="value.hadSpermSurgery"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had an ultrasound of the testicles?"
        v-model="value.hadTesticleUltrasound"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had blood tests for infertility?"
        v-model="value.hadBloodTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a sperm chromatin test?"
        v-model="value.hadSpermChromatinTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a sperm chromatin (DNA Fragmentation - SCSA) test?"
        v-model="value.hadSpermChromatinDnaTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had an sperm penetration assay?"
        v-model="value.hadSpermPenetrationTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had an acrosome reaction test?"
        v-model="value.hadAcrosomeTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had an chromosome analysis (karyotype) test?"
        v-model="value.hadChromosomeAnalysis"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had an evaluation of Y Chromosome deletion?"
        v-model="value.hadChromosomeDeletionEvaluation"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import InputMixins from '../../../mixins/inputRulesMixins';

export default {
  mixins: [InputMixins],
  props: ['value']
};
</script>
