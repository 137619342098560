<template>
  <v-card flat>
    <v-card-title class="justify-center">
      <h3>Male Information Form</h3>
    </v-card-title>
    <v-card-text>
      <Select
        label="Preferred Title"
        v-model="value.preferredTitle"
        :items="['Mr', ...titles]"
        :rules="genericInputRules"
      />
      <v-row>
        <v-col cols="12" md="4">
          <TextField
            label="First Name"
            v-model="value.firstName"
            :rules="genericInputRules"
          />
        </v-col>
        <v-col cols="12" md="4">
          <TextField label="Middle Name" v-model="value.middleName" />
        </v-col>
        <v-col cols="12" md="4">
          <TextField
            label="Last Name"
            v-model="value.lastName"
            :rules="genericInputRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="mr-0 ml-0">
          <DateField
            style="margin-left: 0"
            class="mr-0 ml-0"
            label="Date Of Birth"
            v-model="value.birthDate"
            :rules="genericInputRules"
          />
        </v-col>
        <v-col cols="12" md="6">
          <TextField
            label="Age"
            v-model="value.age"
            type="number"
            :rules="ageOptionalRules"
            :readonly="true"
          />
        </v-col>
      </v-row>
      <Select label="Race" v-model="value.race" :items="races" />
      <TextField
        label="Occupation"
        v-model="value.occupation"
        :rules="genericInputRules"
      />
      <v-card flat>
        <v-card-subtitle class="pr-0 pl-0">Height</v-card-subtitle>
        <v-row>
          <v-col cols="12" md="6">
            <TextField
              label="Feet"
              suffix="ft"
              v-model="value.height.feet"
              type="number"
              max="10"
              :rules="feetNumberRules"
            />
          </v-col>
          <v-col cols="12" md="6">
            <TextField
              label="Inches"
              suffix="in"
              v-model="value.height.inches"
              type="number"
              max="12"
            />
          </v-col>
        </v-row>
      </v-card>
      <TextField
        label="Weight"
        suffix="lbs"
        v-model="value.weight"
        type="number"
        :rules="greaterThanZeroRules"
      />
      <TextField
        label="BMI"
        v-model="value.bmi"
        type="number"
        :rules="greaterThanZeroRules"
      />
      <RadioGroup
        label="Recent change in weight?"
        row
        v-model="value.changeInWeight"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-col class="mr-0 ml-0 pl-0 pr-0">
        <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
          Duration of unprotected intercourse with current relationship?
        </v-card-subtitle>
        <TextField
          label="Enter the months here"
          v-model="value.durationOfUnprotected"
          suffix="months"
          :rules="genericInputRules"
        />
      </v-col>
      <RadioGroup
        label="Are you Circumcised?"
        row
        v-model="value.isCircumcised"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-card-subtitle class="pr-0 pl-0">
        How Often do you and your partner now have intercourse?
      </v-card-subtitle>
      <v-row>
        <v-col cols="12" md="4">
          <TextField
            label="Per Week"
            v-model="value.intercourseWithPartner.weekly"
            type="number"
            :rules="nonNullRule"
          />
        </v-col>
        <v-col cols="12" md="4">
          <TextField
            label="Per Month"
            v-model="value.intercourseWithPartner.monthly"
            type="number"
            :rules="nonNullRule"
          />
        </v-col>
        <v-col cols="12" md="4">
          <TextField
            label="During Ovulation"
            v-model="value.intercourseWithPartner.duringOvulation"
            type="number"
            :rules="nonNullRule"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue';
import InputMixins from '../../../mixins/inputRulesMixins';

export default {
  mixins: [InputMixins],
  props: ['value'],
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.bmiValue(val);
        this.calculateAge(val);
      }
    }
  },
  methods: {
    bmiValue(val) {
      if (val.height.feet && val.height.inches && val.weight) {
        const feet = parseInt(val.height.feet) * 12;
        const inches = parseInt(val.height.inches) + feet;
        const lbs = parseInt(val.weight);
        const bmi = ((lbs / (inches * inches)) * 703).toFixed(2);
        if (lbs > 0 && inches > 0 && bmi !== val.bmi) {
          this.$emit('input', { ...this.value, bmi });
        }
      }
    },
    calculateAge(val) {
      const now = new Date();
      const born = new Date(this.value.birthDate);
      const birthday = new Date(
        now.getFullYear(),
        born.getMonth(),
        born.getDate()
      );
      let age;

      if (now >= birthday) age = now.getFullYear() - born.getFullYear();
      else age = now.getFullYear() - born.getFullYear() - 1;

      if (age > 0 && age !== val.age) {
        this.$emit('input', { ...this.value, age });
      }
    }
  }
};
</script>
