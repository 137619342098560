<template>
  <v-container>
    <!-- FEMALE INFO -->
    <v-stepper v-model="step">
      <v-stepper-header style="flex-wrap: unset">
        <v-stepper-step :complete="step > 1" step="1">
          <!-- Insurance -->
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step
          :rules="[() => (step > 2 ? validFemaleInfo : true)]"
          step="2"
          :complete="step > 2"
        >
          <!-- Demographics -->
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :rules="[() => (step > 3 ? validMenstrualCycle : true)]"
          :complete="step > 3"
          step="3"
        >
          <!-- Cycle History -->
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :rules="[() => (step > 4 ? validGynecologicHistory : true)]"
          step="4"
          :complete="step > 4"
        >
          <!-- Gynecological History -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          :rules="[() => (step > 5 ? validMedicalHistory : true)]"
          step="5"
          :complete="step > 5"
        >
          <!-- Medical History -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          :rules="[() => (step > 6 ? validEnvironmentalFactors : true)]"
          step="6"
          :complete="step > 6"
        >
          <!-- Environmental History -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          :rules="[() => (step > 7 ? validFamilyHistory : true)]"
          step="7"
          :complete="step > 7"
        >
          <!-- Family History -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          :rules="[() => (step > 8 ? validInfertilityTests : true)]"
          step="8"
          :complete="step > 8"
        >
          <!-- Infertility Testing -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          :rules="[() => (step > 9 ? validPastFertilityTreatments : true)]"
          step="9"
          :complete="step > 9"
        >
          <!-- Treatment History -->
        </v-stepper-step>
      </v-stepper-header>
      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-stepper-items>
        <v-stepper-content step="1">
          <!-- INSURANCE FORM -->
          <v-card flat>
            <v-card-title class="justify-center">
              <h3>Insurance Form</h3>
            </v-card-title>
            <InsuranceForm
              ref="formInsurance"
              @changeValid="(valid) => (this.validInsurance = valid)"
              :valid="validInsurance"
              v-model="intakeForm.primaryInsurance"
            />
            <Checkbox
              label="Check if you have Secondary Insurance"
              v-model="intakeForm.hasSecondaryInsurance"
            />
            <InsuranceForm
              @changeValid="(valid) => (this.validInsurance = valid)"
              :valid="validInsurance"
              v-if="intakeForm.hasSecondaryInsurance"
              v-model="intakeForm.secondaryInsurance"
            />
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card flat>
            <v-card-title class="justify-center pl-0 pr-0">
              <h3>Female Information Form</h3>
            </v-card-title>
            <v-form v-model="validFemaleInfo" ref="formFemaleInfo">
              <v-card-text>
                <Select
                  class="mr-0 ml-0"
                  label="Preferred Title"
                  v-model="intakeForm.preferredTitle"
                  :items="titles"
                />
                <v-row>
                  <v-col cols="12" md="4">
                    <TextField
                      class="input-text-field"
                      label="First Name"
                      v-model="intakeForm.firstName"
                      :rules="genericInputRules"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <TextField
                      class="input-text-field"
                      label="Middle Name"
                      v-model="intakeForm.middleName"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <TextField
                      class="input-text-field"
                      label="Last Name"
                      v-model="intakeForm.lastName"
                      :rules="genericInputRules"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="mr-0 ml-0">
                    <DateField
                      label="Date Of Birth"
                      v-model="intakeForm.birthDate"
                      :rules="genericInputRules"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <TextField
                      class="input-text-field"
                      label="Age"
                      v-model="intakeForm.age"
                      :readonly="true"
                      :rules="genericInputRules"
                    />
                  </v-col>
                </v-row>
                <Select
                  class="mr-0 ml-0"
                  label="Race"
                  v-model="intakeForm.race"
                  :items="races"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Occupation"
                  v-model="intakeForm.occupation"
                  :rules="genericInputRules"
                />
                <Select
                  class="mr-0 ml-0"
                  label="Marital Status"
                  v-model="intakeForm.maritalStatus"
                  :items="maritalStatuses"
                />
                <v-card flat>
                  <v-card-subtitle class="pr-0 pl-0">Height</v-card-subtitle>
                  <v-card-text class="pl-0 pr-0">
                    <v-row>
                      <v-col cols="12" md="6">
                        <TextField
                          class="input-text-field"
                          label="Feet"
                          suffix="ft"
                          v-model="intakeForm.height.feet"
                          type="number"
                          :rules="feetNumberRules"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <TextField
                          class="input-text-field"
                          label="Inches"
                          suffix="in"
                          v-model="intakeForm.height.inches"
                          type="number"
                          :rules="inchesNumberRules"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <TextField
                  class="input-text-field"
                  label="Weight"
                  suffix="lbs"
                  v-model="intakeForm.weight"
                  type="number"
                  :rules="numberNotZeroInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="BMI"
                  v-model="intakeForm.bmi"
                  :readonly="true"
                  :rules="numberNotZeroInputRules"
                />
                <TextArea
                  class="mr-0 ml-0"
                  label="Visit Reason"
                  v-model="intakeForm.reasonForVisit"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Total Pregnancies"
                  v-model="intakeForm.totalNumberPregnancies"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Pre-term Births (<37 weeks)"
                  v-model="intakeForm.preTermBirths"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Term Births (>=37 weeks)"
                  v-model="intakeForm.termBirths"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Miscarriages (<20 weeks)"
                  v-model="intakeForm.miscarriages"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Abortions"
                  v-model="intakeForm.abortions"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Ectopic Pregnancies (EP)"
                  v-model="intakeForm.ectopicPregnancies"
                  :rules="genericInputRules"
                />
                <TextField
                  class="input-text-field"
                  label="Biochemical Pregnancies"
                  v-model="intakeForm.biochemicalPregnancies"
                  :rules="genericInputRules"
                />
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    How long have you been trying to achieve a pregnancy?
                    (months of unprotected intercourse or number of insemination
                    cycles)
                  </v-card-subtitle>
                  <TextField
                    class="input-text-field"
                    single-line="false"
                    label="Enter the months here"
                    suffix="months"
                    v-model="intakeForm.monthsTrying"
                    :rules="genericInputRules"
                  />
                </v-col>
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    How long have you been trying to achieve a pregnancy with
                    current partner?
                  </v-card-subtitle>
                  <TextField
                    class="input-text-field"
                    label="Enter the months here"
                    suffix="months"
                    v-model="intakeForm.monthsTryingCurrentPartner"
                    :rules="genericInputRules"
                  />
                </v-col>
              </v-card-text>
            </v-form>

            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <!-- MENSTRUAL CYCLE -->
          <v-card flat>
            <v-card-title class="justify-center">
              <h3>Menstrual Cycle History</h3>
            </v-card-title>
            <v-card-text>
              <v-form v-model="validMenstrualCycle" ref="formMenstrualCycle">
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    At what age did you begin having periods?
                  </v-card-subtitle>

                  <TextField
                    class="input-text-field"
                    label="Enter the age here"
                    suffix="years old"
                    v-model="intakeForm.agePeriodsBegin"
                    type="number"
                    :rules="genericNumberInputRules"
                  />
                </v-col>
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    What was the date of your last menstrual period?
                  </v-card-subtitle>

                  <DateField
                    label="Enter the date here"
                    v-model="intakeForm.lastPeriod"
                    :rules="genericInputRules"
                  />
                </v-col>
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    What is the average length of time your period lasts
                  </v-card-subtitle>
                  <TextField
                    class="input-text-field"
                    label="Enter the days here"
                    suffix="days"
                    v-model="intakeForm.periodAverageLength"
                    type="number"
                    :rules="genericInputRules"
                  />
                </v-col>
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    What is the average length of time in between periods
                  </v-card-subtitle>

                  <TextField
                    class="input-text-field"
                    label="Enter the days here"
                    suffix="days"
                    v-model="intakeForm.daysBetweenPeriods"
                    type="number"
                    :rules="genericInputRules"
                  />
                </v-col>
                <RadioGroup
                  label="Within the last year, have your periods usually come (without medication) every 26-42 days?"
                  row
                  v-model="intakeForm.periodEvery26to32Days"
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <RadioGroup
                  label="Do you ever have bleeding in between periods?"
                  v-model="intakeForm.bleedingBetweenPeriods"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <RadioGroup
                  label="Do you currently need to take medication in order to get a period?"
                  v-model="intakeForm.medicateToGetPeriod"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <RadioGroup
                  label="Do you have pelvic pain with your periods?"
                  v-model="intakeForm.pelvicPainWithPeriods"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <RadioGroup
                  v-if="intakeForm.pelvicPainWithPeriods"
                  v-model="intakeForm.pelvicPainLevel"
                  row
                  label="If yes, please indicate the level of pain you usually experience"
                  :items="['Mild', 'Moderate', 'Severe']"
                />
                <RadioGroup
                  label="Do you often experience pelvic pain in between periods?"
                  v-model="intakeForm.pelvicPainBetweenPeriods"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <v-row v-if="intakeForm.pelvicPainBetweenPeriods">
                  <v-col cols="12" md="6">
                    <RadioGroup
                      v-if="intakeForm.pelvicPainBetweenPeriods"
                      v-model="intakeForm.pelvicPainMedication"
                      label="If yes, do you take medication for pain?"
                      row
                      :items="defaultRadioItems"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <template
                      v-if="
                        intakeForm.pelvicPainMedication &&
                        intakeForm.pelvicPainBetweenPeriods
                      "
                    >
                      <TextField
                        class="input-text-field"
                        v-model="intakeForm.pelvicPainMedicationType"
                        label="Which medication(s)?"
                      />
                      <RadioGroup
                        v-model="intakeForm.pelvicPainMedicationRelievePain"
                        label="Does the medication(s) relieve pain?"
                        row
                        :items="defaultRadioItems"
                      />
                    </template>
                  </v-col>
                </v-row>
                <RadioGroup
                  label="Have you ever used home ovulation predictor kits?"
                  v-model="intakeForm.everUsedHomeOvulationPredictorKits"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <template v-if="intakeForm.everUsedHomeOvulationPredictorKits">
                  <RadioGroup
                    v-model="intakeForm.ovulationEvidence"
                    label="What evidence for ovulation did you see?"
                    :items="['A Positive Test', 'Color Change', 'Other']"
                    row
                  />
                  <v-col
                    class="mr-0 ml-0 pl-2 pr-2"
                    v-if="intakeForm.ovulationEvidence === 'Other'"
                  >
                    <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                      List other
                    </v-card-subtitle>
                    <TextField
                      class="input-text-field"
                      v-model="intakeForm.ovulationEvidenceOther"
                      label="Enter the list here"
                    />
                  </v-col>
                  <v-col class="mr-0 ml-0 pl-2 pr-2">
                    <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                      What day or range of days does it turn positive on?
                    </v-card-subtitle>
                    <TextField
                      class="input-text-field"
                      v-model="intakeForm.ovulationDaysPositive"
                      label="Enter the days here"
                      type="number"
                      suffix="day/days"
                    />
                  </v-col>
                </template>
                <RadioGroup
                  label="Have you ever used any contraceptives?"
                  v-model="intakeForm.everUsedContraceptives"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <template v-if="intakeForm.everUsedContraceptives">
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.contraceptivesUsed.birthControl"
                      label="Birth Control Pills"
                    />
                    <Checkbox
                      v-model="intakeForm.contraceptivesUsed.IUD"
                      label="IUD (Intrauterine Device)"
                    />
                    <Checkbox
                      v-model="intakeForm.contraceptivesUsed.tubalLigation"
                      label="Tubal Ligation (Tubes Tied)"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.contraceptivesUsed.injectableHormones"
                      label="Injectable Hormones"
                    />
                    <Checkbox
                      v-model="intakeForm.contraceptivesUsed.hormonePatch"
                      label="Hormone Patch"
                    />
                    <Checkbox
                      v-model="intakeForm.contraceptivesUsed.other.checked"
                      label="Other"
                    />
                  </v-row>
                  <TextField
                    class="input-text-field"
                    v-if="intakeForm.contraceptivesUsed.other.checked"
                    label="Other Contraceptive:"
                    v-model="intakeForm.contraceptivesUsed.other.comment"
                  />
                </template>
                <RadioGroup
                  label="Are you currently using any contraceptives?"
                  v-model="intakeForm.currentlyUsingContraceptives"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <template v-if="intakeForm.currentlyUsingContraceptives">
                  <v-row>
                    <Checkbox
                      v-model="
                        intakeForm.currentlyUsedContraceptives.birthControl
                      "
                      label="Birth Control Pills"
                    />
                    <Checkbox
                      v-model="intakeForm.currentlyUsedContraceptives.IUD"
                      label="IUD (Intrauterine Device)"
                    />
                    <Checkbox
                      v-model="
                        intakeForm.currentlyUsedContraceptives.tubalLigation
                      "
                      label="Tubal Ligation (Tubes Tied)"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      v-model="
                        intakeForm.currentlyUsedContraceptives
                          .injectableHormones
                      "
                      label="Injectable Hormones"
                    />
                    <Checkbox
                      v-model="
                        intakeForm.currentlyUsedContraceptives.hormonePatch
                      "
                      label="Hormone Patch"
                    />
                    <Checkbox
                      v-model="
                        intakeForm.currentlyUsedContraceptives.other.checked
                      "
                      label="Other"
                    />
                  </v-row>
                  <TextField
                    class="input-text-field"
                    v-if="intakeForm.currentlyUsedContraceptives.other.checked"
                    label="Other Contraceptive:"
                    v-model="
                      intakeForm.currentlyUsedContraceptives.other.comment
                    "
                  />
                </template>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <!-- GYNECOLOGIC HISTORY -->
          <v-card flat>
            <v-card-title class="justify-center">
              <h3>Gynecologic History</h3>
            </v-card-title>
            <v-card-text>
              <v-form
                v-model="validGynecologicHistory"
                ref="formGynecologicHistory"
              >
                <RadioGroup
                  label="Have you had a pap smear?"
                  v-model="intakeForm.hadPapSmear"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />

                <DateField
                  v-if="intakeForm.hadPapSmear"
                  v-model="intakeForm.lastPapSmear"
                  label="When was the last pap done?"
                />
                <RadioGroup
                  v-if="intakeForm.hadPapSmear"
                  v-model="intakeForm.abnormalPapSmear"
                  label="Have you ever had an abnormal pap?"
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                  row
                />
                <TextField
                  class="input-text-field"
                  v-if="intakeForm.abnormalPapSmear"
                  v-model="intakeForm.papSmearAbnormality"
                  label="If yes, what was the abnormality?"
                />
                <RadioGroup
                  label="Have you had a mammogram?"
                  v-model="intakeForm.hadMammogram"
                  :rules="nonNullRule"
                  row
                  :items="defaultRadioItems"
                />
                <DateField
                  v-if="intakeForm.hadMammogram"
                  v-model="intakeForm.lastMammogram"
                  label="If yes, when was the last mammogram?"
                />
                <RadioGroup
                  label="Do you have breast discharge?"
                  v-model="intakeForm.haveBreastDischarge"
                  :rules="nonNullRule"
                  row
                  :items="defaultRadioItems"
                />
                <RadioGroup
                  label="Do you currently have acne?"
                  v-model="intakeForm.haveAcne"
                  :rules="nonNullRule"
                  row
                  :items="defaultRadioItems"
                />
                <RadioGroup
                  label="Do you have unwanted (facial, arm, abdomen, chest or other male pattern) hair growth that requires cosmetic removal?"
                  v-model="intakeForm.haveUnwantedHairGrowth"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    What is your sexual orientation?
                  </v-card-subtitle>
                  <Select
                    class="mr-0 ml-0"
                    label="Select orientation"
                    v-model="intakeForm.sexualOrientation"
                    :items="sexualOrientations"
                    :rules="nonNullRule"
                  />
                </v-col>
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    What is the number of sexual partners you have had in the
                    past 2 years?
                  </v-card-subtitle>

                  <TextField
                    class="input-text-field"
                    label="Enter number here"
                    v-model="intakeForm.numberOfSexualPartnersPast2Years"
                    type="number"
                    :rules="genericNumberInputRules"
                  />
                </v-col>
                <v-row>
                  <v-col cols="12" md="6">
                    <RadioGroup
                      label="Do you have a history of sexual abuse?"
                      v-model="intakeForm.haveHistoryOfSexualAbuse"
                      row
                      :items="defaultRadioItems"
                      :rules="nonNullRule"
                    />
                  </v-col>
                  <template v-if="intakeForm.haveHistoryOfSexualAbuse">
                    <v-col cols="12" md="6">
                      <RadioGroup
                        v-model="intakeForm.haveReceivedCounseling"
                        label="If yes, have you received counseling?"
                        row
                        :items="defaultRadioItems"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <RadioGroup
                        v-model="intakeForm.referralForCounseling"
                        label="Would you like us to make a referral for counseling?"
                        row
                        :items="defaultRadioItems"
                      />
                    </v-col>
                  </template>
                </v-row>
                <RadioGroup
                  label="Do you often have pain with intercourse?"
                  v-model="intakeForm.havePainWithIntercourse"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    How frequently do you have intercourse per week?
                  </v-card-subtitle>
                  <TextField
                    class="input-text-field"
                    label="Enter the quantity here"
                    v-model="intakeForm.frequencyIntercoursePerWeek"
                    type="number"
                    suffix="per week"
                    :rules="genericNumberInputRules"
                  />
                </v-col>
                <RadioGroup
                  label="Do you use lubricants during intercourse? (oil, saliva, KY jelly)?"
                  v-model="intakeForm.useLubricantsWithIntercourse"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />

                <Procedures v-model="intakeForm.procedures" />

                <RadioGroup
                  label="Have you had any exposure to or have been treated for and STD or pelvic infection?"
                  v-model="
                    intakeForm.pelvicInfectionOrSTD.exposedToOrTreatedFor
                  "
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
                <template
                  v-if="intakeForm.pelvicInfectionOrSTD.exposedToOrTreatedFor"
                >
                  <v-row>
                    <Checkbox
                      label="Gonorrhea"
                      v-model="intakeForm.pelvicInfectionOrSTD.gonorrhea"
                    />

                    <Checkbox
                      label="Chlamydia"
                      v-model="intakeForm.pelvicInfectionOrSTD.chlamydia"
                    />

                    <Checkbox
                      label="Herpes"
                      v-model="intakeForm.pelvicInfectionOrSTD.herpes"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      label="Syphilis"
                      v-model="intakeForm.pelvicInfectionOrSTD.syphilis"
                    />

                    <Checkbox
                      label="HPV (Human Papiloma Virus)"
                      v-model="intakeForm.pelvicInfectionOrSTD.HPV"
                    />

                    <Checkbox
                      label="HIV/AIDS"
                      v-model="intakeForm.pelvicInfectionOrSTD.HIV"
                    />
                  </v-row>
                </template>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="5">
          <!-- MEDICAL HISTORY -->
          <v-card flat>
            <v-card-title class="justify-center mb-4">
              <h3>Medical History</h3>
            </v-card-title>
            <v-form v-model="validMedicalHistory" ref="formMedicalHistory">
              <v-col class="mr-0 ml-0 pl-2 pr-2">
                <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                  What is your blood type?
                </v-card-subtitle>
                <TextField
                  class="input-text-field"
                  v-model="intakeForm.bloodType"
                  label="What is your blood type?"
                />
              </v-col>
              <RadioGroup
                v-model="intakeForm.longStandingConditions.hasConditions"
                label="Do you have long-standing medical conditions?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <v-container
                v-if="intakeForm.longStandingConditions.hasConditions"
              >
                <v-card-title
                  style="
                    background: #305676;
                    color: white;
                    width: 100%;
                    word-break: break-word;
                  "
                >
                  Long-Standing Conditions:
                </v-card-title>
                <v-card
                  v-for="(con, index) in conditionsList"
                  :key="'con' + index"
                  width="100%"
                  style="margin-top: 5px"
                >
                  <v-card-text>
                    <v-row align-self="center" style="align-items: center">
                      <v-col cols="12" md="6">
                        <v-card-subtitle class="pr-0 pl-0">
                          Condition:
                        </v-card-subtitle>
                        <v-text-field
                          class="input-text-field"
                          v-model="con.longStandingCondition"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        align-self="center"
                        style="align-items: center"
                      >
                        <v-card-subtitle class="pr-0 pl-0">
                          Comment:
                        </v-card-subtitle>
                        <v-text-field
                          class="input-text-field"
                          v-model="con.comment"
                          @blur="addLongStandingCondition(con, index)"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="index + 1 === conditionsList.length">
                      <v-col
                        cols="12"
                        style="display: flex; justify-content: center"
                      >
                        <v-icon
                          class="mr-2"
                          x-large
                          @click="addLongStandingCondition(con, index)"
                        >
                          mdi-plus
                        </v-icon>

                        <v-icon
                          class="ml-2"
                          x-large
                          @click="removeLongStandingCondition(index)"
                        >
                          mdi-minus
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
              <RadioGroup
                v-model="intakeForm.usedThyroidMedication"
                label="Have you ever needed or used thyroid medication?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.usedThyroidMedication">
                <Label
                  style="word-break: break-word"
                  text="If yes, please check all that apply"
                />
                <Checkbox
                  v-model="intakeForm.thyroidMedicationType.synthroid"
                  label="Synthroid"
                />
                <Checkbox
                  v-model="intakeForm.thyroidMedicationType.levoxyl"
                  label="Levoxyl"
                />
                <v-row>
                  <v-col>
                    <Checkbox
                      v-model="intakeForm.thyroidMedicationType.other"
                      label="Other"
                    />
                  </v-col>
                  <v-col>
                    <TextField
                      class="input-text-field"
                      v-if="intakeForm.thyroidMedicationType.other"
                      v-model="intakeForm.thyroidMedicationType.comment"
                      label="Name other:"
                    />
                  </v-col>
                </v-row>
              </template>
              <RadioGroup
                v-model="intakeForm.medications.currentlyTakingMeds"
                label="Are you currently taking any medication?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <v-container v-if="intakeForm.medications.currentlyTakingMeds">
                <v-card-title
                  style="background: #305676; color: white; width: 100%"
                >
                  Current Medications:
                </v-card-title>
                <v-card
                  v-for="(med, index1) in medicationsList"
                  :key="'med' + index1"
                  width="100%"
                  style="margin-top: 5px"
                >
                  <v-card-text>
                    <v-row align-self="center" style="align-items: center">
                      <v-col cols="12" md="6">
                        <v-card-subtitle class="pr-0 pl-0">
                          Medication:
                        </v-card-subtitle>
                        <v-text-field
                          class="input-text-field"
                          v-model="med.medication"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-card-subtitle class="pr-0 pl-0">
                          Comment:
                        </v-card-subtitle>
                        <v-text-field
                          class="input-text-field"
                          v-model="med.comment"
                          @blur="addMedication(med, index)"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="index1 + 1 === medicationsList.length">
                      <v-col
                        cols="12"
                        style="display: flex; justify-content: center"
                      >
                        <v-icon
                          class="mr-2"
                          x-large
                          @click="addMedication(med, index1)"
                        >
                          mdi-plus
                        </v-icon>

                        <v-icon
                          class="ml-2"
                          x-large
                          @click="removeMedication(index1)"
                        >
                          mdi-minus
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
              <RadioGroup
                v-model="intakeForm.vaccinations.hadFollowingVaccinations"
                label="Have you had the following vaccinations?"
              />
              <v-container class="v-card-top">
                <v-card width="100%">
                  <v-card-title
                    style="
                      background: #305676;
                      color: white;
                      font-weight: normal;
                      word-break: break-word;
                    "
                  >
                    Please check all that apply:
                  </v-card-title>
                  <v-card-text>
                    <v-row align-self="center" style="align-items: center">
                      <v-col cols="12" md="6">
                        <Checkbox
                          label="Measles"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations.measles
                              .checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations.measles
                            .checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .measles.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="German Measles"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations
                              .germanMeasles.checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations
                            .germanMeasles.checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .germanMeasles.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="Tuberculosis"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations
                              .tuberculosis.checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations
                            .tuberculosis.checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .tuberculosis.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="Hepatitis A"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations
                              .hepatitisA.checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations
                            .hepatitisA.checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .hepatitisA.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="Hepatitis B"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations
                              .hepatitisB.checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations
                            .hepatitisB.checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .hepatitisB.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="Chicken Pox"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations
                              .chickenpox.checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations
                            .chickenpox.checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .chickenpox.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="Polio"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations.polio
                              .checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations.polio
                            .checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .polio.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="Tetanus"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations.tetanus
                              .checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations.tetanus
                            .checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .tetanus.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align-self="center" style="align-items: center">
                      <v-col>
                        <Checkbox
                          label="Influenza (Flu)"
                          :disabled="
                            intakeForm.vaccinations.hadFollowingVaccinations ===
                            false
                          "
                          v-model="
                            intakeForm.vaccinations.possibleVaccinations
                              .influenza.checked
                          "
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          intakeForm.vaccinations.possibleVaccinations.influenza
                            .checked
                        "
                      >
                        <v-row>
                          <v-card-title style="font-weight: normal">
                            When:
                          </v-card-title>
                          <v-col>
                            <TextField
                              class="input-text-field"
                              v-model="
                                intakeForm.vaccinations.possibleVaccinations
                                  .influenza.date
                              "
                              :disabled="
                                intakeForm.vaccinations
                                  .hadFollowingVaccinations === false
                              "
                              label="(mm/yyyy)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>

              <RadioGroup
                class="mt-6"
                v-model="intakeForm.hasMedicationOrFoodAllergies"
                label="Are you allergic to or have had an adverse reaction to any medications, foods, or other environmental factors?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hasMedicationOrFoodAllergies">
                <p>Medication Allergies:</p>
                <v-col>
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.NKDA"
                      label="No known drug allergies"
                    />

                    <Checkbox
                      v-model="intakeForm.medicationAllergies.amoxicillin"
                      label="Amoxicillin"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.aspirin"
                      label="Aspirin"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.augmentin"
                      label="Augmentin"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.bacitracin"
                      label="Bacitracin"
                    />

                    <Checkbox
                      v-model="intakeForm.medicationAllergies.bactrim"
                      label="Bactrim"
                    />

                    <Checkbox
                      v-model="intakeForm.medicationAllergies.ceclor"
                      label="Ceclor"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.cipro"
                      label="Cipro"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.codeine"
                      label="Codeine"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.compazine"
                      label="Compazine"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.demerol"
                      label="Demerol"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.doxycycline"
                      label="Doxycycline"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.erythromycin"
                      label="Erythromycin"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.hydrocodone"
                      label="Hydrocodone"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.ibuprofen"
                      label="Ibuprofen"
                    />

                    <Checkbox
                      v-model="intakeForm.medicationAllergies.iodine"
                      label="Iodine"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.keflex"
                      label="Keflex"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.levaquin"
                      label="Levaquin"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.morphine"
                      label="Morphine"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.penicillin"
                      label="PCN/Penicillin"
                    />
                  </v-row>
                  <v-row>
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.percocet"
                      label="Septra"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.sulfa"
                      label="Sulfa"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.tetracycline"
                      label="Tetracycline"
                    />
                    <Checkbox
                      v-model="intakeForm.medicationAllergies.vicodin"
                      label="Vicodin"
                    />
                  </v-row>
                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.medicationAllergies.other"
                    label="Other allergy not listed:"
                  />
                </v-col>
                <RadioGroup
                  label="Do you have food allergies?"
                  :items="defaultRadioItems"
                  v-model="foodAllergy"
                />
                <template v-if="foodAllergy">
                  <p>Food Allergies:</p>
                  <v-col>
                    <v-row>
                      <Checkbox
                        v-model="intakeForm.foodAllergies.eggs"
                        label="Eggs"
                      />
                      <Checkbox
                        v-model="intakeForm.foodAllergies.gluten"
                        label="Gluten"
                      />
                    </v-row>
                    <v-row>
                      <Checkbox
                        v-model="intakeForm.foodAllergies.milk"
                        label="Milk"
                      />
                      <Checkbox
                        v-model="intakeForm.foodAllergies.msg"
                        label="MSG"
                      />
                      <Checkbox
                        v-model="intakeForm.foodAllergies.artificalSweetener"
                        label="Nutrasweet (Artificial Sweetener)"
                      />
                    </v-row>
                    <v-row>
                      <Checkbox
                        v-model="intakeForm.foodAllergies.peanuts"
                        label="Peanuts"
                      />

                      <Checkbox
                        v-model="intakeForm.foodAllergies.solubleFiber"
                        label="Soluble Fiber"
                      />
                      <Checkbox
                        v-model="intakeForm.foodAllergies.soy"
                        label="Soy"
                      />
                    </v-row>
                    <v-row>
                      <Checkbox
                        v-model="intakeForm.foodAllergies.shellfish"
                        label="Shellfish"
                      />
                      <Checkbox
                        v-model="intakeForm.foodAllergies.treeNuts"
                        label="Tree Nuts"
                      />
                      <Checkbox
                        v-model="intakeForm.foodAllergies.wheat"
                        label="Wheat"
                      />
                    </v-row>
                    <v-row>
                      <TextField
                        class="input-text-field"
                        v-model="intakeForm.foodAllergies.other"
                        label="Other allergy not listed:"
                      />
                    </v-row>
                  </v-col>
                </template>
                <RadioGroup
                  label="Do you have other allergies?"
                  :items="defaultRadioItems"
                  v-model="otherAllergy"
                />
                <template v-if="otherAllergy">
                  <p>Other Allergies:</p>
                  <v-col>
                    <v-row>
                      <Checkbox
                        v-model="intakeForm.otherAllergies.cigarettes"
                        label="Cigarettes"
                      />
                      <Checkbox
                        v-model="intakeForm.otherAllergies.cosmetics"
                        label="Cosmetics"
                      />
                      <Checkbox
                        v-model="intakeForm.otherAllergies.latex"
                        label="Latex"
                      />
                      <Checkbox
                        v-model="intakeForm.otherAllergies.pesticide"
                        label="Pesticides"
                      />
                    </v-row>
                    <v-row>
                      <Checkbox
                        v-model="intakeForm.otherAllergies.plastics"
                        label="Plastics"
                      />

                      <Checkbox
                        v-model="intakeForm.otherAllergies.pollen"
                        label="Pollen"
                      />

                      <Checkbox
                        v-model="intakeForm.otherAllergies.vehicleExhaust"
                        label="Vehicle Exhaust"
                      />
                    </v-row>
                    <v-row>
                      <TextField
                        class="input-text-field"
                        v-model="intakeForm.otherAllergies.other"
                        label="Other allergy not listed:"
                      />
                    </v-row>
                  </v-col>
                </template>
              </template>

              <Surgeries class="mr-0 ml-0" v-model="intakeForm.surgeries" />

              <RadioGroup
                v-model="intakeForm.diagnosedHIV"
                label="Have you ever been diagnosed with HIV?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                v-model="intakeForm.useAcupuncture"
                label="Are you currently using acupuncture?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                v-model="intakeForm.usedAcupuncture"
                label="Have you used acupuncture in the past?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                v-model="intakeForm.hadChickenpox"
                label="Have you had Chickenpox?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                v-model="intakeForm.eatingDisorder"
                label="Do you have a history of an eating disorder?"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
            </v-form>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="6">
          <!-- ENVIRONMENTAL FACTORS -->
          <v-card flat>
            <v-card-title class="justify-center mb-4">
              <h3>Environmental Factor</h3>
            </v-card-title>
            <v-form
              v-model="validEnvironmentalFactors"
              ref="formEnvironmentalFactors"
            >
              <RadioGroup
                label="Do you use any nicotine products (cigarettes, cigars, smokeless tobacco, vaping, etc.)?"
                v-model="intakeForm.useNicotine"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <v-col cols="12" md="12" v-if="intakeForm.useNicotine">
                <v-card-subtitle class="pr-0 pl-0">
                  How many nicotine products per day?
                </v-card-subtitle>
                <TextField
                  class="input-text-field"
                  type="number"
                  suffix="# of products"
                  v-model="intakeForm.nicotineProductsPerDay"
                  label="Enter the number"
                  :rules="nonNullRule"
                />
              </v-col>
              <RadioGroup
                label="In the past, have you ever used any nicotine products (cigarettes, cigars, smokeless tobacco, vaping, etc)?"
                v-model="intakeForm.useNicotineInPast"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.useNicotineInPast">
                <v-col cols="12" md="12">
                  <v-card-subtitle class="pr-0 pl-0">
                    How much nicotine products per day on an average day?
                  </v-card-subtitle>
                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.nicotineProductsPerDayInPast"
                    label="Enter the number"
                    type="number"
                    suffix="# of products"
                  />
                </v-col>
                <DateField
                  v-model="intakeForm.nicotineQuitDay"
                  label="When did you quit?"
                />
              </template>
              <RadioGroup
                label="Do you currently drink alcohol?"
                v-model="intakeForm.useAlcohol"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.useAlcohol">
                <RadioGroup
                  v-model="intakeForm.alcoholDrinkingHabit"
                  :items="['Socially', 'Daily', 'Alcoholic-dependent']"
                  label="How do you describe your drinking habits?"
                  :rules="nonNullRule"
                />
                <v-col
                  cols="12"
                  md="12"
                  v-if="intakeForm.alcoholDrinkingHabit === 'Socially'"
                >
                  <v-card-subtitle class="pr-0 pl-0">
                    What is your average drinking amount?
                  </v-card-subtitle>

                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.alchoholDrinkingAmount"
                    label="Enter quantity"
                    type="number"
                    suffix="Per month"
                  />
                </v-col>
                <v-col cols="12" md="12" v-else>
                  <v-card-subtitle class="pr-0 pl-0">
                    What is your average drinking amount?
                  </v-card-subtitle>
                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.alchoholDrinkingAmount"
                    label="Enter quantity"
                    type="number"
                    suffix="Per day"
                  />
                </v-col>

                <RadioGroup
                  label="Have you ever had issues with alcohol dependency/abuse?"
                  v-model="intakeForm.haveAlcoholIssues"
                  row
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                />
              </template>
              <RadioGroup
                label="Do you consume caffeinated beverages?"
                v-model="intakeForm.useCaffeine"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.useCaffeine">
                <RadioGroup
                  v-if="intakeForm.useCaffeine"
                  v-model="intakeForm.numberOfCaffeinatedBeverages"
                  label="If yes, on average, how many cups of coffee do you consume?"
                  :items="[
                    '1-2 per day',
                    '1-2 per week',
                    '1-2 per month',
                    '3-4 per day',
                    '3-4 per week',
                    '3-4 per month',
                    'More than 5 per day',
                    'More than 5 per week',
                    'More than 5 per month'
                  ]"
                  row
                  :rules="nonNullRule"
                />
                <TextField
                  class="input-text-field"
                  v-model="intakeForm.totalTeaOrSoda"
                  label="How many total of tea and/or soda?"
                  type="number"
                />
              </template>
              <RadioGroup
                label="Do you currently use recreational drugs?"
                v-model="intakeForm.useRecreationalDrugs"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <v-col cols="12" md="12" v-if="intakeForm.useRecreationalDrugs">
                <v-card-subtitle class="pr-0 pl-0">
                  If yes, what?
                </v-card-subtitle>
                <TextField
                  class="input-text-field"
                  v-model="intakeForm.recreationalDrugType"
                  label="Enter here"
                />
              </v-col>
              <RadioGroup
                label="Do you use herbal remedies, vitamins, or over the counter medications?"
                v-model="intakeForm.useHerbalRemediesVitaminesOTCMedications"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <v-row>
                <template
                  v-if="intakeForm.useHerbalRemediesVitaminesOTCMedications"
                >
                  <v-col cols="12" md="6">
                    <v-card-subtitle class="pr-0 pl-0">
                      If yes, what?
                    </v-card-subtitle>
                    <TextField
                      class="input-text-field"
                      v-model="
                        intakeForm.herbalRemediesVitaminesOTCMedicationsType
                      "
                      label="Enter here"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card-subtitle class="pr-0 pl-0">
                      How much per day?
                    </v-card-subtitle>
                    <TextField
                      class="input-text-field"
                      v-model="intakeForm.herbalRemediesPerDay"
                      label="Enter here"
                      type="number"
                    />
                  </v-col>
                </template>
              </v-row>
              <RadioGroup
                label="Are you on a special diet?"
                v-model="intakeForm.onSpecialDiet"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.onSpecialDiet">
                <RadioGroup
                  v-model="intakeForm.vegan"
                  label="Vegan?"
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                  row
                />
                <RadioGroup
                  v-model="intakeForm.vegetarian"
                  label="Vegetarian?"
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                  row
                />
                <RadioGroup
                  v-model="intakeForm.glutenFree"
                  label="Gluten Free diet?"
                  :items="defaultRadioItems"
                  row
                  :rules="nonNullRule"
                />
                <v-col cols="12" md="12">
                  <v-card-subtitle class="pr-0 pl-0">
                    Are you on any other kind of diet?
                  </v-card-subtitle>

                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.otherDiet"
                    label="Enter here"
                  />
                </v-col>
              </template>
              <RadioGroup
                label="Do you currently exercise?"
                v-model="intakeForm.currentlyExercise"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.currentlyExercise">
                <v-col cols="12" md="12">
                  <v-card-subtitle class="pr-0 pl-0">
                    How many times do you exercise per week?
                  </v-card-subtitle>

                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.exercisePerWeek"
                    label="Enter here"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-card-subtitle class="pr-0 pl-0">
                    When you exercise, how many minutes on average do you
                    exercise?
                  </v-card-subtitle>
                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.averageTimeExercising"
                    label="Enter here"
                    type="number"
                  />
                </v-col>
                <RadioGroup
                  v-model="intakeForm.averageIntensityOfWorkout"
                  label="On a scale of 1-10, what is the average level of
                intensity of your entire workout? (1=low intensity, 5=moderate,
                10=intense)"
                  :items="[
                    { text: '1', value: 1 },
                    { text: '2', value: 2 },
                    { text: '3', value: 3 },
                    { text: '4', value: 4 },
                    {
                      text: '5',
                      value: 5
                    },
                    { text: '6', value: 6 },
                    { text: '7', value: 7 },
                    { text: '8', value: 8 },
                    { text: '9', value: 9 },
                    {
                      text: '10',
                      value: 10
                    }
                  ]"
                  row
                />
                <v-col cols="12" md="12">
                  <v-card-subtitle class="pr-0 pl-0">
                    What type of exercise do you usually participate in?
                  </v-card-subtitle>

                  <TextField
                    class="input-text-field"
                    v-model="intakeForm.typeOfExercise"
                    label="Enter here"
                  />
                </v-col>
              </template>
              <RadioGroup
                label="Are you worried or concerned about your weight?"
                v-model="intakeForm.concernedAboutWeight"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                label="In the past six months have you traveled to any area where Zika virus has been present or do you plan to travel to one  of these areas in the future?"
                v-model="intakeForm.travelToZikaVirusArea"
                row
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
            </v-form>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="7">
          <!-- FAMILY HISTORY -->
          <v-card flat>
            <v-card-title class="justify-center mb-4">
              <h3>Family History</h3>
            </v-card-title>
            <v-divider />
            <v-form v-model="validFamilyHistory" ref="formFamilyHistory">
              <GeneticFamilyHistory
                v-model="intakeForm.familyMedicalConditions"
              />
              <v-col class="mr-0 ml-0 pl-2 pr-2">
                <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                  How old was your mother when her oldest child was born?
                </v-card-subtitle>
                <TextField
                  class="input-text-field"
                  label="Enter the years here"
                  suffix="years old"
                  v-model="intakeForm.motherAgeAtOldestAndYoungestBirth.oldest"
                  type="number"
                />
              </v-col>
              <v-col class="mr-0 ml-0 pl-2 pr-2">
                <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                  How old was your mother when her youngest child was born?
                </v-card-subtitle>

                <TextField
                  class="input-text-field"
                  label="Enter the years here"
                  suffix="years old"
                  v-model="
                    intakeForm.motherAgeAtOldestAndYoungestBirth.youngest
                  "
                  type="number"
                />
              </v-col>
              <RadioGroup
                label="Do you have a birth defect or familial disorder not listed above?"
                v-model="intakeForm.haveBirthDefectOrFamilialDisorder"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />

              <v-col
                class="mr-0 ml-0 pl-0 pr-0"
                v-if="intakeForm.haveBirthDefectOrFamilialDisorder"
              >
                <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                  If yes, please describe
                </v-card-subtitle>
                <TextField
                  class="input-text-field"
                  v-model="intakeForm.birthDefectDescription"
                  label="Please describe here"
                />
              </v-col>
              <RadioGroup
                row
                label="Have you or a significant other in this or any previous relationships had a stillborn child or more than two first trimester miscarriages?"
                v-model="intakeForm.hadStillbornOrMiscarriages"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <v-row>
                <v-col cols="12" md="6">
                  <Select
                    label="Ancestry of Father"
                    v-model="intakeForm.fatherAncestory"
                    :items="ancestries"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <Select
                    label="Ancestry of Mother"
                    v-model="intakeForm.motherAncestory"
                    :items="ancestries"
                  />
                </v-col>
              </v-row>
              <v-card-subtitle class="ancestry-header" color="primary">
                Eastern European of Jewish Ancestry
              </v-card-subtitle>
              <RadioGroup
                row
                label="Have you had Tay Sach's screening test? (Genetic condition most common in Jews of Eastern European ancestry)"
                v-model="intakeForm.hadTraySachsScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template
                v-if="intakeForm.hadTraySachsScreeningTest"
                style="padding: 0 10px"
              >
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.traySachsFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.traySachsFindings.findings.type"
                  :rules="nonNullRule"
                />
                <TextField
                  class="input-text-field"
                  v-if="
                    intakeForm.traySachsFindings.findings.type === 'Abnormal'
                  "
                  label="Abnormal Findings: "
                  v-model="intakeForm.traySachsFindings.findings.comment"
                />
              </template>
              <RadioGroup
                row
                label="Have you had a Canavan screening test? (Chromosome study)"
                v-model="intakeForm.hadCanavanScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadCanavanScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.canavanFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.canavanFindings.findings.type"
                />
                <TextField
                  class="input-text-field"
                  v-if="intakeForm.canavanFindings.findings.type === 'Abnormal'"
                  label="Abnormal Findings: "
                  v-model="intakeForm.canavanFindings.findings.comment"
                />
              </template>
              <RadioGroup
                row
                label="Have you had a Bloom screening test? (Chromosome study)"
                v-model="intakeForm.hadBloomScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadBloomScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.bloomFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.bloomFindings.findings.type"
                />
                <TextField
                  class="input-text-field"
                  v-if="intakeForm.bloomFindings.findings.type === 'Abnormal'"
                  label="Abnormal Findings: "
                  v-model="intakeForm.bloomFindings.findings.comment"
                />
              </template>
              <RadioGroup
                row
                label="Have you had a Gaucher screening test? (Chromosome study)"
                v-model="intakeForm.hadGaucherScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadGaucherScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.gaucherFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.gaucherFindings.findings.type"
                  :rules="nonNullRule"
                />
                <TextField
                  class="input-text-field"
                  v-if="intakeForm.gaucherFindings.findings.type === 'Abnormal'"
                  label="Abnormal Findings: "
                  v-model="intakeForm.gaucherFindings.findings.comment"
                />
              </template>
              <RadioGroup
                row
                label="Have you had a Fanconi Anemia screening test? (Testing done for Bone Marrow failure)"
                v-model="intakeForm.hadFanconiAnemiaScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadFanconiAnemiaScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.fanconiAnemiaFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.fanconiAnemiaFindings.findings.type"
                />
                <TextField
                  class="input-text-field"
                  v-if="
                    intakeForm.fanconiAnemiaFindings.findings.type ===
                    'Abnormal'
                  "
                  label="Abnormal Findings: "
                  v-model="intakeForm.fanconiAnemiaFindings.findings.comment"
                />
              </template>
              <RadioGroup
                row
                label="Have you had a Neimann-Pick screening test (Genetic testing)"
                v-model="intakeForm.hadNeimannPickScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadNeimannPickScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.neimannPicksFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.neimannPicksFindings.findings.type"
                />
                <TextField
                  class="input-text-field"
                  v-if="
                    intakeForm.neimannPicksFindings.findings.type === 'Abnormal'
                  "
                  label="Abnormal Findings: "
                  v-model="intakeForm.neimannPicksFindings.findings.comment"
                />
              </template>
              <v-card-subtitle class="ancestry-header">
                African Ancestry
              </v-card-subtitle>
              <RadioGroup
                row
                label="Have you had a Sickle Cell screening test? (Inherited blood disease)"
                v-model="intakeForm.hadSickleCellScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadSickleCellScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.sickleCellFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.sickleCellFindings.findings.type"
                />
                <TextField
                  class="input-text-field"
                  v-if="
                    intakeForm.sickleCellFindings.findings.type === 'Abnormal'
                  "
                  label="Abnormal Findings: "
                  v-model="intakeForm.sickleCellFindings.findings.comment"
                />
              </template>
              <v-card-subtitle class="ancestry-header">
                European Ancestry or family member with Cystic Fibrosis
              </v-card-subtitle>
              <RadioGroup
                row
                label="Have you had a Cystic Fibrosis screening test?"
                v-model="intakeForm.hadCysticFibrosisScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadCysticFibrosisScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.cysticFibrosisFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.cysticFibrosisFindings.findings.type"
                />
                <TextField
                  class="input-text-field"
                  v-if="
                    intakeForm.cysticFibrosisFindings.findings.type ===
                    'Abnormal'
                  "
                  label="Abnormal Findings: "
                  v-model="intakeForm.cysticFibrosisFindings.findings.comment"
                />
              </template>
              <v-card-subtitle class="ancestry-header">
                Italian, Greek, Mediterranean or South East Asian Ancestry
              </v-card-subtitle>
              <RadioGroup
                row
                label="Have you had a screening test for inherited forms of anemia (e.g. Thalassemia)?"
                v-model="intakeForm.hadAnemiaInheritedFormsScreeningTest"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <template v-if="intakeForm.hadAnemiaInheritedFormsScreeningTest">
                <TextField
                  class="input-text-field"
                  label="When?"
                  suffix="mm/yyyy"
                  v-model="intakeForm.anemiaFindings.date"
                />
                <RadioGroup
                  label="What were the findings?"
                  :items="['Normal', 'Abnormal', 'Pending']"
                  v-model="intakeForm.anemiaFindings.findings.type"
                />
                <TextField
                  class="input-text-field"
                  v-if="intakeForm.anemiaFindings.findings.type === 'Abnormal'"
                  label="Abnormal Findings: "
                  v-model="intakeForm.anemiaFindings.findings.comment"
                />
              </template>
            </v-form>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="8">
          <!--INFERITILITY TESTS -->
          <v-card flat>
            <v-card-title class="justify-center mb-4">
              <h3>Infertility Tests</h3>
            </v-card-title>
            <v-form v-model="validInfertilityTests" ref="formInfertilityTests">
              <RadioGroup
                row
                label="Ultrasound of the uterus and/or ovaries when not pregnant"
                v-model="intakeForm.hadFertilityTests.ultrasound"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                row
                label="Have you had an Hysterosalpingogram (HSG)? An x-ray test of the uterus during which dye is injected into the uterus to see your fallopian tubes."
                v-model="intakeForm.hadFertilityTests.hysterosalpingogram"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                row
                label="Have you had a Hysterosonogram (also called sonohysterogram)? An ultrasound test in which saline (salt water) is injected into the uterus to view the uterine walls."
                v-model="intakeForm.hadFertilityTests.hysterosonogram"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                row
                label="Have you had a Laparoscopy? A telescope is placed though the belly button to see inside the abdomen"
                v-model="intakeForm.hadFertilityTests.laparoscopy"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                row
                label="Have you had a Hysteroscopy? A telescope is placed through the vagine into the uterus in order to see inside the uterus"
                v-model="intakeForm.hadFertilityTests.hysteroscopy"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <RadioGroup
                row
                label="Have you had any other tests to specifically look at possible causes of infertility, miscarriages, or problems with menstrual cycle?"
                v-model="intakeForm.hadFertilityTests.otherTests"
                :items="defaultRadioItems"
                :rules="nonNullRule"
              />
              <OtherTests
                v-if="intakeForm.hadFertilityTests.otherTests"
                v-model="intakeForm.hadOtherTests"
              />
            </v-form>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn class="primary" @click="moveStep(1)" :loading="loading">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="9">
          <!-- PAST FERTILITY TREATMENTS -->
          <v-card flat>
            <v-card-title class="justify-center mb-4 pl-0 pr-0">
              <h3>Past Fertility Treatments</h3>
            </v-card-title>
            <v-form
              v-model="validPastFertilityTreatments"
              ref="formPastFertilityTreatments"
            >
              <RadioGroup
                label="Have you ever had any past fertility treatments (e.g. Non medicated, Clomiphene Citrate, Gonadotropins, In-Vitro Fertilizations (IVF), Frozen Embryo, Gestational Surrogacy, or Donor Egg Cycle)?"
                v-model="intakeForm.hadFertilityTreatments"
                :items="defaultRadioItems"
                :rules="nonNullRule"
                row
              />
              <!-- clomipheneCitrate -->
              <v-card
                style="margin: 10px"
                v-if="intakeForm.hadFertilityTreatments"
              >
                <v-card-subtitle>Past Fertility Treatment I</v-card-subtitle>
                <v-divider />
                <ClomipheneCitrate v-model="intakeForm.clomipheneCitrate" />
              </v-card>
              <!-- nonIVF -->
              <v-card
                style="margin: 10px"
                v-if="intakeForm.hadFertilityTreatments"
              >
                <v-card-subtitle>Past Fertility Treatment II</v-card-subtitle>
                <v-divider />
                <NonIVF v-model="intakeForm.nonIVF" />
              </v-card>
              <!-- IVF -->
              <v-card
                style="margin: 10px"
                v-if="intakeForm.hadFertilityTreatments"
              >
                <v-card-subtitle>Past Fertility Treatment III</v-card-subtitle>
                <v-divider />
                <IVF v-model="intakeForm.IVF" />
              </v-card>
              <!-- Frozen Embryos -->
              <v-card
                style="margin: 10px"
                v-if="intakeForm.hadFertilityTreatments"
                class="mb-4"
              >
                <v-card-subtitle>Past Fertility Treatment IV</v-card-subtitle>
                <v-divider />
                <FrozenEmbryos v-model="intakeForm.frozenEmbryo" />
              </v-card>
              <template v-if="intakeForm.hadFertilityTreatments">
                <RadioGroup
                  label="Gestational Surrogacy Cycles?"
                  v-model="intakeForm.gestationalSurrogacy"
                  :items="defaultRadioItems"
                  row
                  :rules="nonNullRule"
                />

                <TextField
                  class="input-text-field"
                  v-if="intakeForm.gestationalSurrogacy"
                  label="What was the indication?"
                  v-model="intakeForm.gestationalSurrogacyIndication"
                />

                <RadioGroup
                  label="Have you ever been an egg donor?"
                  v-model="intakeForm.eggDonor"
                  :items="defaultRadioItems"
                  row
                  :rules="nonNullRule"
                />
              </template>
            </v-form>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="moveStep(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn color="primary" @click="moveStep(1)" :loading="loading">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-overlay :value="overlay">
      <ThankYou />
    </v-overlay>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { api, auth } from '../../sharedPlugin.js';
import ThankYou from './ThankYou.vue';
import FrozenEmbryos from './femaleForm/FrozenEmbryos.vue';
import Procedures from './femaleForm/Procedures.vue';
import OtherTests from './femaleForm/OtherTests.vue';
import ClomipheneCitrate from './femaleForm/ClomipheneCitrate.vue';
import NonIVF from './femaleForm/NonIVF.vue';
import IVF from './femaleForm/IVF.vue';
import inputRulesMixins from '../../mixins/inputRulesMixins.js';
import GeneticFamilyHistory from './shared/GeneticFamilyHistory.vue';
import InsuranceForm from './shared/InsuranceForm.vue';
import Surgeries from './femaleForm/Surgeries.vue';
export default {
  components: {
    ThankYou,
    Procedures,
    OtherTests,
    FrozenEmbryos,
    ClomipheneCitrate,
    NonIVF,
    IVF,
    GeneticFamilyHistory,
    InsuranceForm,
    Surgeries
  },
  props: ['value'],
  mixins: [inputRulesMixins],

  data() {
    return {
      loading: false,
      overlay: false,
      id: null,
      validInsurance: false,
      validFemaleInfo: false,
      validMenstrualCycle: false,
      validGynecologicHistory: false,
      validMedicalHistory: false,
      validEnvironmentalFactors: false,
      validFamilyHistory: false,
      validInfertilityTests: false,
      validPastFertilityTreatments: false,
      step: 1,
      foodAllergy: null,
      otherAllergy: null,
      intakeForm: null,
      conditionsList: [{ longStandingCondition: '', comment: '' }],
      medicationsList: [{ medication: '', comment: '' }],
      // Used in selects
      titles: ['Mr.', 'Miss', 'Mrs.', 'Ms.'],
      races: [
        'American Indian or Alaska Native',
        'Asian',
        'Black or African American',
        'Hispanic or Latino',
        'Native Hawaiian or Other Pcific Islander',
        'White'
      ],
      maritalStatuses: [
        'Single',
        'Married or Re-Married',
        'Seperated',
        'Divorced',
        'Widowed'
      ],
      sexualOrientations: [
        'Heterosexual',
        'Homosexual',
        'Bisexual',
        'Never been sexually active'
      ],
      ancestries: [
        'Asian Ancestry',
        'Eastern European of Jewish Ancestry',
        'African Ancestry',
        'European Ancestry',
        'Italian Ancestry',
        'Greek Ancestry',
        'Mediterranian Ancestry',
        'South East Asian Ancestry'
      ]
    };
  },
  mounted() {
    if (this.value.lastStep > 1) {
      setTimeout(() => {
        this.moveStep(this.value.lastStep - 1);
      }, 500);
    }
  },
  watch: {
    intakeForm: {
      deep: true,
      handler(val) {
        this.bmiValue();
        this.calculateAge();
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.intakeForm = api.IntakeFormTranslate(val.formData);
        this.id = val.id;

        if (
          this.intakeForm &&
          this.intakeForm.longStandingConditions &&
          this.intakeForm.longStandingConditions.listOfConditions
        ) {
          this.conditionsList =
            this.intakeForm.longStandingConditions.listOfConditions;
        }

        if (
          this.intakeForm &&
          this.intakeForm.medications &&
          this.intakeForm.medications.medicationsTaken
        ) {
          this.medicationsList = this.intakeForm.medications.medicationsTaken;
        }
      }
    }
  },
  methods: {
    ...auth.mapMethods(['finishForm']),
    async updateForm() {
      this.loading = true;
      return await api
        .IntakeFormUpdate(
          this.intakeForm,
          (newForm) => {
            this.intakeForm = newForm;
          },
          this.id,
          this.step,
          this.formatIntakeForm
        )
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    formatIntakeForm() {
      let data = this.formatDataObj(this.intakeForm);

      return {
        formType: 'FemaleForm',
        ...data
      };
    },
    formatDataObj(oldObj) {
      if (!oldObj) return null;

      return Object.keys(oldObj).reduce((obj, key) => {
        if (Array.isArray(oldObj[key])) {
          obj[key] = this.formatObjectArray(oldObj[key]);
        } else {
          obj[key] = this.checkIfRadioBtnReturnObj(oldObj[key]);
        }
        return obj;
      }, {});
    },
    checkIfRadioBtnReturnObj(obj) {
      if (typeof obj !== 'object') return obj;

      return obj?.value ?? this.formatDataObj(obj);
    },
    formatObjectArray(array) {
      if (array.length === 0) return [];

      return array.map((obj) => this.formatDataObj(obj));
    },
    validate() {
      Promise.all([
        this.$refs.formInsurance.validate(),
        this.$refs.formFemaleInfo.validate(),
        this.$refs.formMenstrualCycle.validate(),
        this.$refs.formGynecologicHistory.validate(),
        this.$refs.formMedicalHistory.validate(),
        this.$refs.formEnvironmentalFactors.validate(),
        this.$refs.formFamilyHistory.validate(),
        this.$refs.formInfertilityTests.validate(),
        this.$refs.formPastFertilityTreatments.validate()
      ]).then(() => this.submit());
    },
    submit() {
      if (
        !this.validInsurance ||
        !this.validFemaleInfo ||
        !this.validMenstrualCycle ||
        !this.validGynecologicHistory ||
        !this.validMedicalHistory ||
        !this.validEnvironmentalFactors ||
        !this.validFamilyHistory ||
        !this.validInfertilityTests ||
        !this.validPastFertilityTreatments
      ) {
        this.$store.commit('showSnackbar', {
          message: 'Please fill out all missing fields.',
          color: 'red'
        });
      } else {
        this.finishForm(this.id);
      }
    },
    reset() {
      this.$refs.formFemaleInfo.reset();
      this.$refs.formMenstrualCycle.reset();
      this.$refs.formGynecologicHistory.reset();
      this.$refs.formMedicalHistory.reset();
      this.$refs.formEnvironmentalFactors.reset();
      this.$refs.formFamilyHistory.reset();
      this.$refs.formInfertilityTests.reset();
      this.$refs.formPastFertilityTreatments.reset();
    },
    removeLongStandingCondition(index) {
      if (this.conditionsList.length === 1) {
        return;
      }
      this.conditionsList.splice(index, 1);
      this.updateLongStandingConditions();
    },
    addLongStandingCondition(con, index) {
      var blankCondition = { longStandingCondition: '', comment: '' };
      if (con.longStandingCondition === '') {
        return;
      } else {
        this.conditionsList[index] = con;
        this.conditionsList.push(blankCondition);
        this.updateLongStandingConditions();
      }
    },
    updateLongStandingConditions() {
      var copyList = [...this.conditionsList];
      var last = copyList.length - 1;
      if (copyList[last].longStandingCondition === '') {
        copyList.splice(last, 1);
      }

      this.intakeForm.longStandingConditions.listOfConditions = [...copyList];
    },
    removeMedication(index1) {
      if (this.medicationsList.length === 1) {
        return;
      }
      this.medicationsList.splice(index1, 1);
      this.updateMedicationList();
    },
    addMedication(med, index1) {
      var blankMedication = { medication: '', comment: '' };
      if (med.medication === '') {
        return;
      } else {
        this.medicationsList[index1] = med;
        this.medicationsList.push(blankMedication);
        this.updateMedicationList();
      }
    },
    updateMedicationList() {
      var copyList = [...this.medicationsList];
      var last = copyList.length - 1;
      if (copyList[last].medication === '') {
        copyList.splice(last, 1);
      }

      this.intakeForm.medications.medicationsTaken = [...copyList];
    },
    bmiValue() {
      if (this.intakeForm !== undefined) {
        const feet = parseInt(this.intakeForm.height.feet) * 12;
        const inches = parseInt(this.intakeForm.height.inches) + feet;
        const lbs = parseInt(this.intakeForm.weight);
        const bmi = ((lbs / (inches * inches)) * 703).toFixed(2);
        if (lbs > 0 && inches > 0) {
          Vue.set(this.intakeForm, 'bmi', bmi);
          return bmi;
        } else {
          Vue.set(this.intakeForm, 'bmi', null);
          return null;
        }
      } else {
        Vue.set(this.intakeForm, 'bmi', null);
        return null;
      }
    },
    calculateAge() {
      if (!this.intakeForm.birthDate) {
        return;
      }
      const now = new Date();
      const born = new Date(this.intakeForm.birthDate);
      const birthday = new Date(
        now.getFullYear(),
        born.getMonth(),
        born.getDate()
      );
      let difference;

      if (now >= birthday) difference = now.getFullYear() - born.getFullYear();
      else difference = now.getFullYear() - born.getFullYear() - 1;

      Vue.set(this.intakeForm, 'age', difference);
    },
    async moveStep(num) {
      this.loading = true;
      const steps = {
        1: {
          validate: () => this.$refs.formInsurance.validate(),
          valid: this.validInsurance
        },
        2: {
          validate: () => this.$refs.formFemaleInfo.validate(),
          valid: this.validFemaleInfo
        },
        3: {
          validate: () => this.$refs.formMenstrualCycle.validate(),
          valid: this.validMenstrualCycle
        },
        4: {
          validate: () => this.$refs.formGynecologicHistory.validate(),
          valid: this.validGynecologicHistory
        },
        5: {
          validate: () => this.$refs.formMedicalHistory.validate(),
          valid: this.validMedicalHistory
        },
        6: {
          validate: () => this.$refs.formEnvironmentalFactors.validate(),
          valid: this.validEnvironmentalFactors
        },
        7: {
          validate: () => this.$refs.formFamilyHistory.validate(),
          valid: this.validFamilyHistory
        },
        8: {
          validate: () => this.$refs.formInfertilityTests.validate(),
          valid: this.validInfertilityTests
        },
        9: {
          validate: () => this.$refs.formPastFertilityTreatments.validate(),
          valid: this.validPastFertilityTreatments
        }
      };

      await this.updateForm();
      const insurance = await this.$refs.formInsurance.validate();

      if (this.step === 1 && !insurance) {
        return;
      } else if (num > 0) {
        const step = steps[this.step];
        await step.validate();

        if (steps[this.step].valid) {
          if (this.step === 9) {
            return this.$emit('finishForm');
          }
          this.step = this.step + num;
        }
      } else {
        this.step = this.step + num;
      }

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.loading = false;
    }
  }
};
</script>

<style scoped>
@media (max-width: 450px) {
  .h3 {
    white-space: nowrap;
  }
}
.input-field {
  margin: 10px;
}
.input-text-field {
  margin-left: 5px;
  margin-right: 5px;
}
.v-card-top {
  margin-top: -45px;
}
.ancestry-header.v-card__subtitle {
  color: blue;
  padding-left: 0px;
}
.v-card__text {
  color: rgba(0, 0, 0, 0.6);
}
</style>
