export const unescape = (html) => {
  const entityMap = {
    '&nbsp;': ' ',
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&#x2F;': '/',
    '&#x60;': '`',
    '&#x3D;': '='
  };

  return html.replace(
    /&(nbsp|pamp|lt|gt|quot|#39|#x2F|#x60|#x3D);/g,
    (match, entity) => {
      return entityMap[match] || match;
    }
  );
};

export const formatIntakeForm = (resultObj) => {
  return {
    ...resultObj,
    procedures: mapObject(resultObj.procedures, (obj) =>
      obj.checked ? removeCheckedKey(obj) : null
    ),
    hadOtherTests: mapObject(resultObj.hadOtherTests, (obj) =>
      obj.checked ? removeCheckedKey(obj) : null
    )
  };
};

const removeCheckedKey = (oldObj) => {
  return Object.keys(oldObj)
    .filter((key) => key !== 'checked')
    .reduce((obj, key) => {
      obj[key] = oldObj[key];
      return obj;
    }, {});
};

const mapObject = (oldObj, mapFn) => {
  return Object.keys(oldObj).reduce((obj, key) => {
    obj[key] = mapFn(oldObj[key]);
    return obj;
  }, {});
};

/**
 * @description Builds a Object array based on the passed in array,
 * the new length the array should be and what the default object structure looks like in the Array
 * @param {Array} arr - Base Array that is being updated
 * @param {Number} n - New length of the updated Array
 * @param {Object} obj - Default object to be inserted into the Array when size is increased
 */
export const buildObjectArray = (arr, n, obj) => {
  if (!n || !arr) return [];
  const newArr = [...arr];

  while (newArr.length != n) {
    if (newArr.length < n) {
      newArr.push(obj);
    } else {
      newArr.pop();
    }
  }

  return newArr;
};

export const getAbbreviatedTimezone = (name) => {
  var timezones = [
    {
      name: 'ATLANTIC STANDARD TIME',
      abbr: 'AST',
      offset: -4
    },
    {
      name: 'EASTERN STANDARD TIME',
      abbr: 'EST',
      offset: -5
    },
    {
      name: 'EASTERN DAYLIGHT TIME',
      abbr: 'EDT',
      offset: -4
    },
    {
      name: 'CENTRAL STANDARD TIME',
      abbr: 'CST',
      offset: -6
    },
    {
      name: 'CENTRAL DAYLIGHT TIME',
      abbr: 'CDT',
      offset: -5
    },
    {
      name: 'MOUNTAIN STANDARD TIME',
      abbr: 'MST',
      offset: -7
    },
    {
      name: 'MOUNTAIN DAYLIGHT TIME',
      abbr: 'MDT',
      offset: -6
    },
    {
      name: 'PACIFIC STANDARD TIME',
      abbr: 'PST',
      offset: -8
    },
    {
      name: 'PACIFIC DAYLIGHT TIME',
      abbr: 'PDT',
      offset: -7
    },
    {
      name: 'ALASKA TIME',
      abbr: 'AKST',
      offset: -9
    },
    {
      name: 'ALASKA DAYLIGHT TIME',
      abbr: 'AKDT',
      offset: -8
    },
    {
      name: 'HAWAII STANDARD TIME',
      abbr: 'HST',
      offset: -10
    },
    {
      name: 'HAWAII-ALEUTIAN STANDARD TIME',
      abbr: 'HAST',
      offset: -10
    },
    {
      name: 'HAWAII-ALEUTIAN DAYLIGHT TIME',
      abbr: 'HADT',
      offset: -9
    }
  ];

  return {
    abbr:
      '(' +
      timezones.find(
        (timezone) => timezone.name.toUpperCase() === name.toUpperCase()
      ).abbr +
      ')',
    offset: timezones.find(
      (timezone) => timezone.name.toUpperCase() === name.toUpperCase()
    ).offset
  };
};

export const getTimezoneByState = (state) => {
  var timezones = [
    {
      state: 'Alabama',
      initial: 'AL',
      abbr: 'CST'
    },
    {
      state: 'Alaska',
      initial: 'AK',
      abbr: 'AKST'
    },
    {
      state: 'Arizona',
      initial: 'AZ',
      abbr: 'MST'
    },
    {
      state: 'Arkansas',
      initial: 'AR',
      abbr: 'CST'
    },
    {
      state: 'California',
      initial: 'CA',
      abbr: 'PDT' //'PST'
    },
    {
      state: 'Colorado',
      initial: 'CO',
      abbr: 'MST'
    },
    {
      state: 'Connecticut',
      initial: 'CT',
      abbr: 'EST'
    },
    {
      state: 'Delaware',
      initial: 'DE',
      abbr: 'EST'
    },
    {
      state: 'District of Columbia',
      initial: 'DC',
      abbr: 'EST'
    },
    {
      state: 'Florida',
      initial: 'FL',
      abbr: 'EST'
    },
    {
      state: 'Georgia',
      initial: 'GA',
      abbr: 'EST'
    },
    {
      state: 'Hawaii',
      initial: 'HI',
      abbr: 'HST'
    },
    {
      state: 'Idaho',
      initial: 'ID',
      abbr: 'MST'
    },
    {
      state: 'Illinois',
      initial: 'IL',
      abbr: 'CST'
    },
    {
      state: 'Indiana',
      initial: 'IN',
      abbr: 'EST'
    },
    {
      state: 'Iowa',
      initial: 'IA',
      abbr: 'CST'
    },
    {
      state: 'Kansas',
      initial: 'KS',
      abbr: 'CST'
    },
    {
      state: 'Kentucky',
      initial: 'KY',
      abbr: 'CST'
    },
    {
      state: 'Louisiana',
      initial: 'LA',
      abbr: 'CST'
    },
    {
      state: 'Maine',
      initial: 'ME',
      abbr: 'EST'
    },
    {
      state: 'Maryland',
      initial: 'MD',
      abbr: 'EST'
    },
    {
      state: 'Massachusetts',
      initial: 'MA',
      abbr: 'EST'
    },
    {
      state: 'Michigan',
      initial: 'MI',
      abbr: 'EST'
    },
    {
      state: 'Minnesota',
      initial: 'MN',
      abbr: 'CST'
    },
    {
      state: 'Mississippi',
      initial: 'MS',
      abbr: 'CST'
    },
    {
      state: 'Missouri',
      initial: 'MO',
      abbr: 'CST'
    },
    {
      state: 'Montana',
      initial: 'MT',
      abbr: 'MST'
    },
    {
      state: 'Nebraska',
      initial: 'NE',
      abbr: 'CST'
    },
    {
      state: 'Nevada',
      initial: 'NV',
      abbr: 'PST'
    },
    {
      state: 'New Hampshire',
      initial: 'NH',
      abbr: 'EST'
    },
    {
      state: 'New Jersey',
      initial: 'NJ',
      abbr: 'EST'
    },
    {
      state: 'New Mexico',
      initial: 'NM',
      abbr: 'MST'
    },
    {
      state: 'New York',
      initial: 'NY',
      abbr: 'EST'
    },
    {
      state: 'North Carolina',
      initial: 'NC',
      abbr: 'EST'
    },
    {
      state: 'North Dakota',
      initial: 'ND',
      abbr: 'CST'
    },
    {
      state: 'Western North Dakota',
      initial: 'WND',
      abbr: 'MST'
    },
    {
      state: 'Northern Mariana Islands',
      initial: 'MP',
      abbr: 'ChST'
    },
    {
      state: 'Ohio',
      initial: 'OH',
      abbr: 'EST'
    },
    {
      state: 'Oklahoma',
      initial: 'OK',
      abbr: 'CST'
    },
    {
      state: 'Oregon',
      initial: 'OR',
      abbr: 'CST'
    },
    {
      state: 'Pennsylvania',
      initial: 'PA',
      abbr: 'EST'
    },
    {
      state: 'Puerto Rico',
      initial: 'PR',
      abbr: 'AST'
    },
    {
      state: 'Rhode Island',
      initial: 'RI',
      abbr: 'EST'
    },
    {
      state: 'South Carolina',
      initial: 'SC',
      abbr: 'EST'
    },
    {
      state: 'South Dakota',
      initial: 'SD',
      abbr: 'CST'
    },
    {
      state: 'Western South Dakota',
      initial: 'WSD',
      abbr: 'MST'
    },
    {
      state: 'Tennessee',
      initial: 'TN',
      abbr: 'CST'
    },
    {
      state: 'Texas',
      initial: 'TX',
      abbr: 'CST'
    },
    {
      state: 'United States Virgin Islands',
      initial: 'USVI',
      abbr: 'AST'
    },
    {
      state: 'Utah',
      initial: 'UT',
      abbr: 'MST'
    },
    {
      state: 'Vermont',
      initial: 'VT',
      abbr: 'EST'
    },
    {
      state: 'Virginia',
      initial: 'VA',
      abbr: 'EST'
    },
    {
      state: 'Washington',
      initial: 'WA',
      abbr: 'EST'
    },
    {
      state: 'West Virginia',
      initial: 'WV',
      abbr: 'EST'
    },
    {
      state: 'Wisconsin',
      initial: 'WI',
      abbr: 'CST'
    },
    {
      state: 'Wyoming',
      initial: 'WY',
      abbr: 'MST'
    }
  ];
  return (
    '(' +
    timezones.find(
      (timezone) => timezone.initial.toUpperCase() === state.toUpperCase()
    ).abbr +
    ')'
  );
};
