<template>
  <v-col>
    <p>Other Tests</p>
    <DateFinding
      v-for="otherTestsLabel in otherTestsLabels"
      :key="otherTestsLabel.key"
      :checkBoxLabel="otherTestsLabel.label"
      v-model="value[otherTestsLabel.key]"
      :textFieldLabel="textFieldLabel"
    />
  </v-col>
</template>

<script>
import InputMixins from '../../../mixins/inputRulesMixins';
import DateFinding from './DateFinding.vue';

export default {
  props: ['value'],
  mixins: [InputMixins],
  components: { DateFinding },
  data: () => ({
    otherTestsLabels: [
      { label: 'Day 3 FSH (Folicile Stimulating Hormone)', key: 'day3FSH' },
      { label: 'Day 3 Estradiol', key: 'day3Estradiol' },
      { label: 'Clomid Challenge Test (CCT) - Day 3 FSH', key: 'CCTDay3FSH' },
      { label: 'Clomid Challenge Test (CCT) - Day 10 FSH', key: 'CCTDay10FSH' },
      { label: 'Semen Analysis (Partner)', key: 'semenAnalysis' },
      { label: 'Blood test to verify ovulation', key: 'bloodTest' },
      {
        label: 'Basal Body Temperature Chart (BBT)',
        key: 'basalBodyTemperature'
      },
      { label: 'Endometrial Biopsy', key: 'endometrialBiopsy' },
      { label: 'Inhibin', key: 'inhibin' },
      { label: 'Post Coital Test', key: 'postCoitalTest' },
      { label: 'Anti-Sperm Antibodies (Female)', key: 'antiSpermAntibodies' },
      {
        label: 'Chromosome Analysis (Karyotype) - Female',
        key: 'chromsomeAnalysisFemale'
      },
      {
        label: 'Chromosome Analysis (Karyotype) - Male Partner',
        key: 'chromsomeAnalysisMalePartner'
      },
      { label: 'Lupus-Anticoagulant', key: 'lupusAnticoagulant' },
      { label: 'TSH', key: 'TSH' },
      { label: 'Prolactin', key: 'prolactin' },
      { label: 'LH', key: 'LH' },
      { label: 'Progesterone', key: 'progesterone' },
      { label: 'Antimullerian Hormone (AMH)', key: 'antimullerianHormone' },
      { label: 'Factor V Leiden Mutation', key: 'factorVLeidenMutation' },
      { label: 'Prothrombin Mutation', key: 'prothrombinMutation' },
      { label: 'Testosterone', key: 'testosterone' },
      { label: 'Other test taken', key: 'other' }
    ],
    textFieldLabel: 'Test Results'
  })
};
</script>
