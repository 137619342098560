import { buildObjectArray } from '../utils/helpers';
import _ from 'lodash';

//Cycle mixin requires generateDefault function in order to work correctly
export default {
  data: () => ({
    spermDelivMethods: ['IJI', 'Intercourse'],
    deliveryTypes: ['Vaginal Delivery', 'C-Section', 'Other'],
    monitoringOptions: ['None', 'Ovulation Kit', 'Blood/US']
  }),
  watch: {
    numberOfCycles: _.debounce(function (newVal) {
      if (newVal > 30) {
        return this.$store.commit('showSnackbar', {
          message: 'The maximum number of cycles to provide is 30.',
          color: 'error'
        });
      }
      if (newVal === 0) {
        this.$emit('input', {
          ...this.value,
          cycles: []
        });
      } else {
        //function that builds default objects based on new value
        this.$emit('input', {
          ...this.value,
          numberOfCycles: newVal,
          cycles: buildObjectArray(
            this.value.cycles,
            newVal,
            this.generateDefault()
          )
        });
      }
    }, 500),
    checked(newVal) {
      //if checked is null or false we should reset data
      if (!newVal) {
        this.$emit('input', {
          ...this.value,
          numberOfCycles: null,
          cycles: []
        });
      }
    }
  },
  computed: {
    numberOfCycles() {
      return this.value.numberOfCycles;
    },
    checked() {
      return this.value.haveYouHadCycle;
    }
  }
};
