<template>
  <v-card-text>
    <RadioGroup
      label="Have you ever had Frozen Embryo Cycles? (check if yes)"
      v-model="value.haveYouHadCycle"
      :items="defaultRadioItems"
      row
    />
    <TextField
      label="Number of"
      suffix="cycles"
      type="number"
      v-model.number="value.numberOfCycles"
      :disabled="!value.haveYouHadCycle"
      watch-change="true"
    />
    <v-list>
      <v-list-item
        v-for="(frozenEmbryoCycle, index) in value.cycles"
        :key="index"
      >
        <v-list-item-content>
          <v-card-subtitle
            class="pl-0 pr-0 pb-8"
            style="font-size: 1.5rem; font-weight: 700; text-align: center"
          >
            Cycle {{ index + 1 }}
          </v-card-subtitle>
          <v-row>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <DateField label="Date" v-model="frozenEmbryoCycle.date" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <Select
                    label="Natural or Medicated Cycle"
                    :items="['Natural', 'Medicated']"
                    v-model="frozenEmbryoCycle.naturalOrMedicated"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <DateField
                    label="Transfer Day"
                    v-model="frozenEmbryoCycle.transferDay"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-row>
            <v-row>
              <v-container>
                <v-col cols="6">
                  <v-container>
                    <TextField
                      label="# Thawed"
                      type="number"
                      v-model="frozenEmbryoCycle.thawedAmount"
                    />
                    <TextField
                      label="# Survived"
                      type="number"
                      v-model="frozenEmbryoCycle.survivedAmount"
                    />
                    <TextField
                      label="# Transferred"
                      type="number"
                      v-model="frozenEmbryoCycle.transferredAmount"
                    />
                  </v-container>
                </v-col>
              </v-container>
            </v-row>
            <v-row>
              <v-container>
                <v-col cols="12">
                  <h3 style="word-break: break-word">Outcomes</h3>
                  <v-checkbox
                    dense
                    v-model="frozenEmbryoCycle.outcome.pregnancy"
                    label="Pregnancy"
                  />
                  <v-checkbox
                    dense
                    v-model="frozenEmbryoCycle.outcome.noOvulation"
                    label="No Ovulations"
                  />
                  <v-checkbox
                    dense
                    v-model="frozenEmbryoCycle.outcome.noPregnancy"
                    label="No Pregnancy"
                  />
                  <v-checkbox
                    dense
                    v-model="frozenEmbryoCycle.outcome.OHSS"
                    label="OHSS"
                  />
                  <v-checkbox
                    dense
                    v-model="frozenEmbryoCycle.outcome.birthDefects"
                    label="Birth Defects"
                  />
                  <v-checkbox
                    dense
                    v-model="frozenEmbryoCycle.outcome.abortion"
                    label="Abortion"
                  />
                  <v-checkbox
                    dense
                    v-model="frozenEmbryoCycle.outcome.miscarriage"
                    label="Miscarriage"
                  />
                </v-col>
              </v-container>
            </v-row>
            <v-row>
              <v-container>
                <v-col>
                  <Select
                    label="Delivery Type"
                    v-model="frozenEmbryoCycle.deliveryType"
                    :items="deliveryTypes"
                  />
                </v-col>
              </v-container>
            </v-row>
          </v-row>
          <v-divider />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script>
import { inputRulesMixins, cyclesMixins } from '../../../mixins/';

const frozenEmbryoCycleTemplate = () => ({
  date: '',
  naturalOrMedicated: '',
  transferDay: '',
  thawedAmount: null,
  survivedAmount: null,
  transferredAmount: null,
  outcome: {
    pregnancy: false,
    noOvulation: false,
    noPregnancy: false,
    OHSS: false,
    birthDefects: false,
    abortion: false,
    miscarriage: false
  },
  deliveryType: ''
});

export default {
  props: ['value'],
  mixins: [inputRulesMixins, cyclesMixins],
  methods: {
    generateDefault() {
      return frozenEmbryoCycleTemplate();
    }
  }
};
</script>
