<template>
  <v-card light>
    <v-card-title class="justify-center">
      Thank You for registering.
    </v-card-title>
    <v-card-subtitle>
      You have completed the entire questionnaire.
    </v-card-subtitle>
    <v-btn dark color="primary" block @click="goBack">Close</v-btn>
  </v-card>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push({ path: this.$route.query.redirect ?? '/' });
    }
  }
};
</script>
