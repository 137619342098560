<template>
  <v-container>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          :rules="[() => (e1 > 1 ? validDemographics : true)]"
        >
          <!-- {{ device === 'mobile' ? '' : 'Demographics' }} -->
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
          :rules="[() => (e1 > 2 ? validPregnancyInfo : true)]"
        >
          <!-- Pregnancy History -->
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="3"
          :complete="e1 > 3"
          :rules="[() => (e1 > 3 ? validMedicalHistory : true)]"
        >
          <!-- Medical History -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          step="4"
          :complete="e1 > 4"
          :rules="[() => (e1 > 4 ? validEnvironmentalFactor : true)]"
        >
          <!-- Environmental History -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          step="5"
          :complete="e1 > 5"
          :rules="[() => (e1 > 5 ? validGeneticFamilyHistory : true)]"
        >
          <!-- Family History -->
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          step="6"
          :complete="e1 > 6"
          :rules="[() => (e1 > 6 ? validMaleInfertilityTests : true)]"
        >
          <!-- Infertility History -->
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="7" :complete="e1 > 7">
          <!-- Insurance -->
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items class="mb-6">
        <v-stepper-content step="1">
          <!-- MALE INFO -->
          <v-form v-model="validDemographics" ref="validDemographics">
            <MaleInformation v-model="intakeForm.maleInformation" />
          </v-form>
          <v-card-actions class="justify-end">
            <!-- <v-btn
              color="error"
              @click="$emit('deleteForm')"
              :loading="loading"
            >
              Restart
            </v-btn> -->
            <v-btn class="primary" @click="step(1)" :loading="loading">
              Next
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content step="2">
          <!-- PREGNANCY INFORMATION -->
          <v-form v-model="validPregnancyInfo" ref="validPregnancyInfo">
            <PregnancyInfoTable v-model="intakeForm.pregnancyInfo" />
          </v-form>
          <v-card-actions class="justify-end">
            <!-- <v-btn
              color="error"
              @click="$emit('deleteForm')"
              :loading="loading"
            >
              Restart
            </v-btn> -->
            <v-btn class="alert" @click="step(-1)" :loading="loading">
              Back
            </v-btn>
            <v-btn class="primary" @click="step(1)" :loading="loading">
              Next
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content step="3">
          <!-- MEDICAL HISTORY -->
          <v-form v-model="validMedicalHistory" ref="validMedicalHistory">
            <MedicalHistory v-model="intakeForm.medicalHistory" />
          </v-form>
          <v-card-actions class="justify-end">
            <!-- <v-btn
              color="error"
              @click="$emit('deleteForm')"
              :loading="loading"
            >
              Restart
            </v-btn> -->
            <v-btn class="alert" @click="step(-1)" :loading="loading">
              Back
            </v-btn>
            <v-btn class="primary" @click="step(1)" :loading="loading">
              Next
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content step="4">
          <!-- ENVIRONMENTAL FACTOR -->
          <v-form
            v-model="validEnvironmentalFactor"
            ref="validEnvironmentalFactor"
          >
            <EnvironmentalFactor v-model="intakeForm.environmentalFactor" />
          </v-form>
          <v-card-actions class="justify-end">
            <!-- <v-btn
              color="error"
              @click="$emit('deleteForm')"
              :loading="loading"
            >
              Restart
            </v-btn> -->
            <v-btn class="alert" @click="step(-1)" :loading="loading">
              Back
            </v-btn>
            <v-btn class="primary" @click="step(1)" :loading="loading">
              Next
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content step="5">
          <!-- GENETIC FAMILY HISTORY -->
          <v-form
            v-model="validGeneticFamilyHistory"
            ref="validGeneticFamilyHistory"
          >
            <FamilyHistory v-model="intakeForm.geneticFamilyHistory" />
          </v-form>
          <v-card-actions class="justify-end">
            <!-- <v-btn
              color="error"
              @click="$emit('deleteForm')"
              :loading="loading"
            >
              Restart
            </v-btn> -->
            <v-btn class="alert" @click="step(-1)" :loading="loading">
              Back
            </v-btn>
            <v-btn class="primary" @click="step(1)" :loading="loading">
              Next
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content step="6">
          <!-- MALE INFERTILITY TESTS -->
          <v-form
            v-model="validMaleInfertilityTests"
            ref="validMaleInfertilityTests"
          >
            <MaleInfertilityTests v-model="intakeForm.maleInfertilityTests" />
          </v-form>
          <v-card-actions class="justify-end">
            <!-- <v-btn
              color="error"
              @click="$emit('deleteForm')"
              :loading="loading"
            >
              Restart
            </v-btn> -->
            <v-btn class="alert" @click="step(-1)" :loading="loading">
              Back
            </v-btn>
            <v-btn class="primary" @click="step(1)" :loading="loading">
              Next
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content step="7">
          <!-- INSURANCE FORM -->
          <v-card flat>
            <v-card-title class="justify-center">
              <h3>Insurance Form</h3>
            </v-card-title>
            <InsuranceForm
              ref="formInsurance"
              @changeValid="
                (valid) => {
                  this.validInsurance = valid;
                  updateForm();
                }
              "
              :valid="validInsurance"
              v-model="intakeForm.primaryInsurance"
            />
            <Checkbox
              label="Check if you have Secondary Insurance"
              v-model="intakeForm.hasSecondaryInsurance"
            />
            <InsuranceForm
              @changeValid="
                (valid) => {
                  this.validInsurance = valid;
                  updateForm();
                }
              "
              :valid="validInsurance"
              v-if="intakeForm.hasSecondaryInsurance"
              v-model="intakeForm.secondaryInsurance"
            />
            <v-card-actions class="justify-end">
              <!-- <v-btn
                color="error"
                @click="$emit('deleteForm')"
                :loading="loading"
              >
                Restart
              </v-btn> -->
              <v-btn class="alert" @click="step(-1)" :loading="loading">
                Back
              </v-btn>
              <v-btn color="primary" @click="step(1)" :loading="loading">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-overlay :value="overlay">
      <ThankYou />
    </v-overlay>
  </v-container>
</template>

<script>
import ThankYou from './ThankYou.vue';
import MaleInformation from './maleForm/MaleInformation.vue';
import PregnancyInfoTable from './maleForm/PregnancyInfoTable.vue';
import MedicalHistory from './maleForm/MedicalHistory.vue';
import EnvironmentalFactor from './maleForm/EnvironmentalFactor.vue';
import MaleInfertilityTests from './maleForm/MaleInfertilityTests.vue';
import { api, auth } from '../../sharedPlugin.js';
import FamilyHistory from './maleForm/FamilyHistory.vue';
import InsuranceForm from './shared/InsuranceForm.vue';
import Checkbox from '../inputs/Checkbox.vue';
import device from '../../utils/device.js';

export default {
  props: ['value'],
  components: {
    MaleInformation,
    PregnancyInfoTable,
    MedicalHistory,
    EnvironmentalFactor,
    MaleInfertilityTests,
    ThankYou,
    FamilyHistory,
    InsuranceForm,
    Checkbox
  },
  data: () => ({
    e1: 1,
    loading: false,
    overlay: false,
    validDemographics: false,
    validPregnancyInfo: false,
    validMedicalHistory: false,
    validEnvironmentalFactor: false,
    validGeneticFamilyHistory: false,
    validMaleInfertilityTests: false,
    validInsurance: true,
    id: null,
    intakeForm: null
  }),
  computed: {
    device() {
      return device;
    }
  },
  mounted() {
    if (this.value.lastStep > 1) {
      this.step(this.value.lastStep - 1);
    }
  },
  methods: {
    ...auth.mapMethods(['finishForm']),
    async updateForm() {
      this.loading = true;
      return await api
        .IntakeFormUpdate(
          this.intakeForm,
          (newForm) => {
            this.intakeForm = newForm;
          },
          this.id,
          this.e1,
          this.formatIntakeForm
        )
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$refs.validDemographics.reset();
      this.$refs.validPregnancyInfo.reset();
      this.$refs.validMedicalHistory.reset();
      this.$refs.validEnvironmentalFactor.reset();
      this.$refs.validGeneticFamilyHistory.reset();
      this.$refs.validMaleInfertilityTests.reset();
      this.$refs.formInsurance.reset();
    },
    validate() {
      Promise.all([
        this.$refs.validDemographics.validate(),
        this.$refs.validPregnancyInfo.validate(),
        this.$refs.validMedicalHistory.validate(),
        this.$refs.validEnvironmentalFactor.validate(),
        this.$refs.validGeneticFamilyHistory.validate(),
        this.$refs.validMaleInfertilityTests.validate(),
        this.$refs.formInsurance.validate()
      ]).then(() => this.submit());
    },
    submit() {
      if (
        !this.validDemographics ||
        !this.validPregnancyInfo ||
        !this.validMedicalHistory ||
        !this.validEnvironmentalFactor ||
        !this.validGeneticFamilyHistory ||
        !this.validMaleInfertilityTests ||
        !this.validInsurance
      ) {
        this.$store.commit('showSnackbar', {
          message: 'Please fill out all missing fields.',
          color: 'red'
        });
      } else {
        this.finishForm(this.id);
      }
    },
    formatIntakeForm() {
      let {
        medicalHistory,
        maleInformation,
        environmentalFactor,
        geneticFamilyHistory,
        maleInfertilityTests,
        pregnancyInfo,
        primaryInsurance,
        secondaryInsurance,
        hasSecondaryInsurance
      } = this.formatDataObj(this.intakeForm);

      if (!this.intakeForm.geneticFamilyHistory.hasMedicalConditions?.value) {
        geneticFamilyHistory.familyConditions = {};
      } else {
        const conditions = this.formatGeneticFamily(
          geneticFamilyHistory.familyConditions
        );
        geneticFamilyHistory = {
          ...geneticFamilyHistory,
          familyConditions: conditions
        };
      }

      return {
        formType: 'MaleForm',
        ...maleInformation,
        pregnancyInfo: {
          ...pregnancyInfo
        },
        primaryInsurance: {
          ...primaryInsurance
        },
        secondaryInsurance: {
          ...secondaryInsurance
        },
        hasSecondaryInsurance: hasSecondaryInsurance,
        ...medicalHistory,
        ...geneticFamilyHistory,
        ...environmentalFactor,
        ...maleInfertilityTests
      };
    },
    formatGeneticFamily(geneticFamilyHistory) {
      if (geneticFamilyHistory === {}) return geneticFamilyHistory;

      return geneticFamilyHistory.reduce((obj, el) => {
        obj[el.key] = {
          self: el.self,
          mother: el.mother,
          father: el.father,
          brother: el.brother,
          child: el.child,
          sister: el.sister,
          grandfather: el.grandfather,
          grandmother: el.grandmother
        };

        return obj;
      }, {});
    },
    async step(num) {
      this.loading = true;
      const steps = {
        1: {
          validate: () => this.$refs.validDemographics.validate(),
          valid: this.validDemographics
        },
        2: {
          validate: () => this.$refs.validPregnancyInfo.validate(),
          valid: this.validPregnancyInfo
        },
        3: {
          validate: () => this.$refs.validMedicalHistory.validate(),
          valid: this.validMedicalHistory
        },
        4: {
          validate: () => this.$refs.validEnvironmentalFactor.validate(),
          valid: this.validEnvironmentalFactor
        },
        5: {
          validate: () => this.$refs.validGeneticFamilyHistory.validate(),
          valid: this.validGeneticFamilyHistory
        },
        6: {
          validate: () => this.$refs.validMaleInfertilityTests.validate(),
          valid: this.validMaleInfertilityTests
        },
        7: {
          validate: () => this.$refs.formInsurance.validate(),
          valid: this.validInsurance
        }
      };

      await this.updateForm();

      const insurance = await this.$refs.formInsurance.validate();

      if (num > 0 && this.e1 === 7 && !insurance) {
        return;
      } else if (num > 0) {
        const step = steps[this.e1];
        await step.validate();

        if (steps[this.e1].valid) {
          if (this.e1 === 7) {
            return this.$emit('finishForm');
          }
          this.e1 = this.e1 + num;
        }
      } else {
        this.e1 = this.e1 + num;
      }

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.loading = false;
    },
    formatDataObj(oldObj) {
      if (!oldObj) return null;

      return Object.keys(oldObj).reduce((obj, key) => {
        if (Array.isArray(oldObj[key])) {
          obj[key] = this.formatObjectArray(oldObj[key]);
        } else {
          obj[key] = this.checkIfRadioBtnReturnObj(oldObj[key]);
        }
        return obj;
      }, {});
    },
    checkIfRadioBtnReturnObj(obj) {
      if (typeof obj !== 'object') return obj;

      return obj?.value ?? this.formatDataObj(obj);
    },
    formatObjectArray(array) {
      if (array.length === 0) return [];

      return array.map((obj) => this.formatDataObj(obj));
    }
  },
  watch: {
    intakeForm: {
      deep: true,
      handler(val) {}
    },
    value: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.intakeForm = api.IntakeFormTranslate(val.formData);
        this.id = val.id;
      }
    }
  }
};
</script>
