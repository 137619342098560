<template>
  <v-card flat>
    <v-card-title
      style="
        background: #1f6070;
        color: white;
        font-weight: normal;
        word-break: break-word;
      "
    >
      Do you or anyone in your family have any of the following medical
      conditions?
    </v-card-title>
    <v-card-text class="pr-0 pl-0">
      <v-data-table
        :headers="tableHeaders"
        :items="medicalConditions"
        item-key="condition"
        :hide-default-footer="true"
        :items-per-page="50"
        :disable-sort="true"
      >
        <template v-slot:[`item.self`]="{ item }">
          <!-- :ripple="false" is a fix to a vue bug that causes 200+ console errors -->
          <v-simple-checkbox v-model="item.self" :ripple="false" />
        </template>
        <template v-slot:[`item.mother`]="{ item }">
          <v-simple-checkbox v-model="item.mother" :ripple="false" />
        </template>
        <template v-slot:[`item.father`]="{ item }">
          <v-simple-checkbox v-model="item.father" :ripple="false" />
        </template>
        <template v-slot:[`item.brother`]="{ item }">
          <v-simple-checkbox v-model="item.brother" :ripple="false" />
        </template>
        <template v-slot:[`item.sister`]="{ item }">
          <v-simple-checkbox v-model="item.sister" :ripple="false" />
        </template>
        <template v-slot:[`item.child`]="{ item }">
          <v-simple-checkbox v-model="item.child" :ripple="false" />
        </template>
        <template v-slot:[`item.grandmother`]="{ item }">
          <v-simple-checkbox v-model="item.grandmother" :ripple="false" />
        </template>
        <template v-slot:[`item.grandfather`]="{ item }">
          <v-simple-checkbox v-model="item.grandfather" :ripple="false" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import InputMixins from '../../../mixins/inputRulesMixins';

export default {
  props: ['value'],
  mixins: [InputMixins],
  data: () => ({
    tableHeaders: [
      { text: 'Medical Conditions', value: 'label', align: 'start' },
      { text: 'Self', value: 'self', align: 'center' },
      { text: 'Mother', value: 'mother', align: 'center' },
      { text: 'Father', value: 'father', align: 'center' },
      { text: 'Brother', value: 'brother', align: 'center' },
      { text: 'Sister', value: 'sister', align: 'center' },
      { text: 'Child', value: 'child', align: 'center' },
      { text: 'Grandmother', value: 'grandmother', align: 'center' },
      { text: 'Grandfather', value: 'grandfather', align: 'center' }
    ],
    medicalConditions: []
  }),
  watch: {
    medicalConditions: {
      deep: true,
      handler(newVal) {
        //convert back to object and emit new value
        const familyConditions = this.formatGeneticFamily(newVal);
        this.$emit('input', familyConditions);
      }
    }
  },
  methods: {
    convertObjectToArray(obj) {
      var medicalConditions = [];

      for (var [key, value] of Object.entries(obj)) {
        medicalConditions.push({
          condition: key,
          label: this.formatCondition(key),
          ...value
        });
      }
      return medicalConditions;
    },
    formatCondition(key) {
      var tempString = key
        .match(/([A-Z]?[^A-Z]*)/g)
        .slice(0, -1)
        .join(' ');
      var tempChar = tempString.charAt(0);
      tempChar = tempChar.toUpperCase();
      tempString = this.setChar(tempString, 0, tempChar);
      return tempString;
    },
    setChar(tempString, index, chr) {
      if (index > tempString.length - 1) return tempString;
      return (
        tempString.substring(0, index) + chr + tempString.substring(index + 1)
      );
    },
    formatGeneticFamily(geneticFamilyHistory) {
      if (geneticFamilyHistory === {}) return geneticFamilyHistory;
      return geneticFamilyHistory.reduce((obj, el) => {
        obj[el.condition] = {
          self: el.self,
          mother: el.mother,
          father: el.father,
          brother: el.brother,
          child: el.child,
          sister: el.sister,
          grandfather: el.grandfather,
          grandmother: el.grandmother
        };

        return obj;
      }, {});
    }
  },
  mounted() {
    this.medicalConditions = this.convertObjectToArray(this.value);
  }
};
</script>
