<template>
  <v-card flat>
    <v-card-title class="justify-center">
      <h3>Pregnancy Information</h3>
    </v-card-title>
    <v-card-text>
      <RadioGroup
        row
        label="Have you ever had a pregnacy in the current relationship or other relationship?"
        v-model="internalHaveFathered"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-container v-if="showTable">
        <v-col class="mr-0 ml-0 pl-0 pr-0">
          <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
            If yes, how many times(regardless of outcome or delivery)?
          </v-card-subtitle>
          <TextField
            label="Enter number here"
            v-model.number="numberOfPregnancies"
            type="number"
            watch-change="true"
          />
        </v-col>
        <v-data-table
          v-if="items.length > 0"
          :headers="headers"
          :items="[...items]"
          hide-default-footer
          disable-sort
          :hide-default-headers="isMobile"
          :class="{ mobile: isMobile, tablePregancy: true }"
          style="border: 1px solid #e0e0e0"
        >
          <template v-slot:[`item`]="{ item, index }" v-if="!isMobile">
            <tr>
              <td class="d-block d-sm-table-cell">
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    Pregnancy date
                  </v-card-subtitle>

                  <DateField
                    :value="item.date"
                    label="Select"
                    :rules="genericInputRules"
                    @input="(val) => changeField(val, 'date', index)"
                  />
                </v-col>
              </td>
              <td class="d-block d-sm-table-cell">
                <RadioGroup
                  v-model.lazy="item.gender"
                  :items="['Male', 'Female']"
                  :rules="nonNullRule"
                  @input="(val) => changeField(val, 'gender', index)"
                />
              </td>
              <td class="d-block d-sm-table-cell">
                <RadioGroup
                  :value="item.isCurrentPartner"
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                  @input="(val) => changeField(val, 'isCurrentPartner', index)"
                />
              </td>
              <td class="d-block d-sm-table-cell">
                <v-col class="mr-0 ml-0 pl-2 pr-2">
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    Time To Conception
                  </v-card-subtitle>

                  <TextField
                    :value="item.timeToConception"
                    label="Months"
                    type="number"
                    :rules="genericInputRules"
                    @input="
                      (val) => changeField(val, 'timeToConception', index)
                    "
                  />
                </v-col>
              </td>
              <td class="d-block d-sm-table-cell">
                <RadioGroup
                  row
                  :value="item.difficultyConceiving"
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                  @input="
                    (val) => changeField(val, 'difficultyConceiving', index)
                  "
                />
              </td>
              <td class="d-block d-sm-table-cell">
                <RadioGroup
                  row
                  :value="item.pastFertilityTreatment.hadTreatment"
                  :items="defaultRadioItems"
                  :rules="nonNullRule"
                  @input="
                    (val) =>
                      changeField(
                        val,
                        'pastFertilityTreatment.hadTreatment',
                        index
                      )
                  "
                />
                <v-col
                  class="mr-0 ml-0 pl-2 pr-2"
                  v-if="item.pastFertilityTreatment.hadTreatment"
                >
                  <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
                    Type of treatment?
                  </v-card-subtitle>

                  <TextField
                    :value="item.pastFertilityTreatment.type"
                    label="Type here"
                    :rules="genericInputRules"
                    :disabled="
                      isDisabled(item.pastFertilityTreatment.hadTreatment)
                    "
                    @input="
                      (val) =>
                        changeField(val, 'pastFertilityTreatment.type', index)
                    "
                  />
                </v-col>
              </td>
              <td class="d-block d-sm-table-cell">
                <RadioGroup
                  :value="item.outcome"
                  :items="outcomes"
                  :rules="nonNullRule"
                  @input="(val) => changeField(val, 'outcome', index)"
                />
              </td>
              <td class="d-block d-sm-table-cell">
                <RadioGroup
                  :value="item.deliveryType"
                  :items="deliveryTypes"
                  :rule="nonNullRule"
                  @input="(val) => changeField(val, 'deliveryType', index)"
                />
                <TextField
                  :value="item.deliveryTypeOther"
                  v-if="item.deliveryType === 'Other'"
                  :rules="genericInputRules"
                  @input="(val) => changeField(val, 'deliveryTypeOther', index)"
                />
              </td>
            </tr>
          </template>
          <template v-slot:[`item`]="{ item, index }" v-else>
            <tr>
              <td>
                <ul>
                  <li>{{ `Pregnacy ${index + 1}` }}</li>
                  <li class="d-block d-sm-table-cell" data-label="Date">
                    <DateField
                      label="Pregnancy date"
                      :value="item.date"
                      :rules="genericInputRules"
                      @input="(val) => changeField(val, 'date', index, items)"
                    />
                  </li>
                  <li class="d-block d-sm-table-cell" data-label="Sex">
                    <RadioGroup
                      :value="item.gender"
                      :items="['Male', 'Female']"
                      :rules="nonNullRule"
                      @input="(val) => changeField(val, 'gender', index)"
                    />
                  </li>
                  <li
                    class="d-block d-sm-table-cell"
                    data-label="Current Partner"
                  >
                    <RadioGroup
                      :value="item.isCurrentPartner"
                      :items="defaultRadioItems"
                      :rules="nonNullRule"
                      @input="
                        (val) => changeField(val, 'isCurrentPartner', index)
                      "
                    />
                  </li>
                  <li
                    class="d-block d-sm-table-cell"
                    data-label="Months to Conception"
                  >
                    <TextField
                      :value="item.timeToConception"
                      type="number"
                      :rules="genericInputRules"
                      @input="
                        (val) => changeField(val, 'timeToConception', index)
                      "
                    />
                  </li>
                  <li
                    class="d-block d-sm-table-cell"
                    data-label="Difficulty Conceiving"
                  >
                    <RadioGroup
                      :value="item.difficultyConceiving"
                      :items="defaultRadioItems"
                      :rules="nonNullRule"
                      @input="
                        (val) => changeField(val, 'difficultyConceiving', index)
                      "
                    />
                  </li>
                  <li
                    class="d-block d-sm-table-cell"
                    data-label="Fertility Treatment"
                  >
                    <RadioGroup
                      row
                      :value="item.pastFertilityTreatment.hadTreatment"
                      :items="defaultRadioItems"
                      :rules="nonNullRule"
                      @input="
                        (val) =>
                          changeField(
                            val,
                            'pastFertilityTreatment.hadTreatment',
                            index
                          )
                      "
                    />
                    <TextField
                      label="Type of treatment"
                      :value="item.pastFertilityTreatment.type"
                      :rules="genericInputRules"
                      :disabled="
                        isDisabled(item.pastFertilityTreatment.hadTreatment)
                      "
                      @input="
                        (val) =>
                          changeField(val, 'pastFertilityTreatment.type', index)
                      "
                    />
                  </li>
                  <li class="d-block d-sm-table-cell" data-label="Outcome">
                    <RadioGroup
                      :value="item.outcome"
                      :items="outcomes"
                      :rules="nonNullRule"
                      @input="(val) => changeField(val, 'outcome', index)"
                    />
                  </li>
                  <li
                    class="d-block d-sm-table-cell"
                    data-label="Delivery Type"
                  >
                    <RadioGroup
                      :value="item.deliveryType"
                      :items="deliveryTypes"
                      :rule="nonNullRule"
                      @input="(val) => changeField(val, 'deliveryType', index)"
                    />
                    <TextField
                      v-if="item.deliveryType === 'Other'"
                      :value="item.deliveryTypeOther"
                      :rules="genericInputRules"
                      @input="
                        (val) => changeField(val, 'deliveryTypeOther', index)
                      "
                    />
                  </li>
                </ul>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InputMixins from '../../../mixins/inputRulesMixins';
import { buildObjectArray } from '../../../utils/helpers';
import device from '../../../utils/device.js';
import _, { forEach } from 'lodash';

const defaultPregnancyObject = () => ({
  date: '',
  gender: null,
  isCurrentPartner: null,
  timeToConception: null,
  difficultyConceiving: null,
  pastFertilityTreatment: {
    hadTreatment: null,
    type: null
  },
  outcome: null,
  deliveryType: null,
  deliveryTypeOther: null
});

export default {
  props: ['value'],
  mixins: [InputMixins],
  data: () => ({
    internalHaveFathered: null,
    numberOfPregnancies: 0,
    headers: [
      { text: 'Date', value: 'date', divider: true },
      { text: 'Sex', value: 'gender', divider: true },
      { text: 'Current Partner', value: 'isCurrentPartner', divider: true },
      {
        text: 'Months to Conception',
        value: 'timeToConception',
        divider: true
      },
      {
        text: 'Difficulty Conceiving?',
        value: 'difficultyConceiving',
        divider: true
      },
      {
        text: 'Fertility Treatment',
        value: 'pastFertilityTreatment',
        divider: true
      },
      { text: 'Outcome', value: 'outcome', divider: true },
      { text: 'Delivery Type', value: 'deliveryType' }
    ],
    items: [],
    outcomes: [
      'Term',
      'Pre-Term',
      'Ectopic',
      'Abortion',
      'Miscarriage',
      'Living'
    ],
    deliveryTypes: ['Vaginal Delivery', 'C-Section', 'Other']
  }),
  computed: {
    showTable() {
      return this.internalHaveFathered?.value ?? this.internalHaveFathered;
    },
    isMobile() {
      return window.innerWidth < 769 || device === 'mobile';
    }
  },
  methods: {
    isDisabled(boolean) {
      const val = boolean?.value ?? boolean;
      return !val ?? true;
    },
    changeField(val, field, index, isObject) {
      const cloneItems = JSON.parse(JSON.stringify(this.items));

      const fields = field.split('.');

      if (fields && fields.length > 1) {
        if (fields.length === 2) {
          cloneItems[index][fields[0]][fields[1]] = val;
        }
      } else {
        cloneItems[index][field] = val;
      }

      this.items = cloneItems;
    }
  },
  watch: {
    numberOfPregnancies: _.debounce(function (val) {
      if (val > 30) {
        return this.$store.commit('showSnackbar', {
          message: 'The maximum number to provide is 30.',
          color: 'error'
        });
      }
      this.$emit('input', {
        ...this.value,
        numberOfPregnancies: val,
        pregnancies: buildObjectArray(
          [...this.items],
          val,
          defaultPregnancyObject()
        )
      });
    }, 500),
    internalHaveFathered(val) {
      if (!val) {
        this.items = [];
        this.numberOfPregnacies = 0;
      }
      this.$emit('input', { ...this.value, haveFathered: val });
    },
    items(val) {
      this.$emit('input', { ...this.value, pregnancies: val });
    },
    value(val) {
      this.internalHaveFathered = val.haveFathered;
      this.items = val.pregnancies;
      this.numberOfPregnancies = val.numberOfPregnancies;
    }
  },
  created() {
    this.internalHaveFathered = this.value.haveFathered;
    this.items = JSON.parse(JSON.stringify(this.value.pregnancies));
    this.numberOfPregnancies = this.value.numberOfPregnancies;
  }
};
</script>

<style scoped>
.tablePregancy table tr td {
  border-right: 1px solid #ccc;
  padding: 20px 20px 20px 20px;
}
.tablePregancy table tr td:last-child {
  border-right: none;
}
@media screen and (max-width: 768px) {
  .mobile table tr td ul {
    padding-left: 0px;
  }
  .mobile table tr td ul li {
    border-bottom: 1px solid grey;
    padding: 20px 20px 20px 20px;
  }
  .mobile table tr td ul li:first-child {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
  .mobile table tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
}
</style>
