<template>
  <v-card flat>
    <v-card-title class="justify-center">
      <h3>Family History</h3>
    </v-card-title>
    <v-card-text>
      <GeneticFamilyHistory v-model="value.familyMedicalConditions" />
      <v-divider class="mb-10" />
      <v-col class="mr-0 ml-0 pl-0 pr-0">
        <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
          How old was your mother when her youngest child was born?
        </v-card-subtitle>
        <TextField
          class="input-text-field"
          label="Enter the age here"
          suffix="years"
          v-model="value.motherAgeAtOldestAndYoungestBirth.youngest"
          type="number"
          :rules="greaterThanZeroOptionalRules"
        />
      </v-col>
      <v-col class="mr-0 ml-0 pl-0 pr-0">
        <v-card-subtitle class="mr-0 ml-0 pl-0 pr-0">
          How old was your mother when her oldest child was born?
        </v-card-subtitle>
        <TextField
          class="input-text-field"
          label="Enter the age here"
          suffix="years"
          v-model="value.motherAgeAtOldestAndYoungestBirth.oldest"
          type="number"
          :rules="greaterThanZeroOptionalRules"
        />
      </v-col>
      <RadioGroup
        row
        label="Do you have a birth defect or familial disorder not listed above?"
        v-model="value.haveBirthDefectOrFamilialDisorder"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you or a significant other in this or any previous relationships had a stillborn childor more than two first trimester miscarriages?"
        v-model="value.hadStillbornOrMiscarriages"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" md="6">
          <Select
            label="Ancestry of Father"
            v-model="value.fatherAncestory"
            :items="ancestries"
            :rules="genericInputRules"
          />
        </v-col>
        <v-col class="pt-0" cols="12" md="6">
          <Select
            label="Ancestry of Mother"
            v-model="value.motherAncestory"
            :items="ancestries"
            :rules="genericInputRules"
          />
        </v-col>
      </v-row>
      <v-card-subtitle class="ancestry-header">
        Eastern European of Jewish Ancestry
      </v-card-subtitle>
      <RadioGroup
        row
        label="Have you had a Tay Sach's screening test? (genetic condition most common in Jews of Eastern European ancestry)"
        v-model="value.hadTraySachsScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a Canavan screening test? (Chromosome study)"
        v-model="value.hadCanavanScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a Bloom screening test? (Chromosome study)"
        v-model="value.hadBloomScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a Gaucher screening test? (Chromosome study)"
        v-model="value.hadGaucherScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a Fanconi Anemia screening test? (testing done for Bone Marrow failure)"
        v-model="value.hadFanconiAnemiaScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <RadioGroup
        row
        label="Have you had a Neimann-Pick screening test (Genetic testing)"
        v-model="value.hadNeimannPickScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-card-subtitle class="ancestry-header">
        African Ancestry
      </v-card-subtitle>
      <RadioGroup
        row
        label="Have you had a Sickle Cell screening test? (inherited blood disease)"
        v-model="value.hadSickleCellScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-card-subtitle class="ancestry-header">
        European Ancestry or family member with Cystic Fibrosis
      </v-card-subtitle>
      <RadioGroup
        row
        label="Have you had a Cystic Fibrosis screening test?"
        v-model="value.hadCysticFibrosisScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
      <v-card-subtitle class="ancestry-header">
        Italian, Greek, Mediterranean or South East Asian Ancestry
      </v-card-subtitle>
      <RadioGroup
        row
        label="Have you had a screening test for inherited forms of anemia (e.g., Thalassemia)?"
        v-model="value.hadAnemiaInheritedFormsScreeningTest"
        :items="defaultRadioItems"
        :rules="nonNullRule"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { inputRulesMixins } from '../../../mixins';
import GeneticFamilyHistory from '../shared/GeneticFamilyHistory.vue';

export default {
  components: { GeneticFamilyHistory },
  props: ['value'],
  mixins: [inputRulesMixins],
  data: () => ({
    ancestries: [
      'Eastern European of Jewish Ancestry',
      'Asian Ancestry',
      'African Ancestry',
      'European Ancestry',
      'Italian Ancestry',
      'Greek Ancestry',
      'Mediterranian Ancestry',
      'South East Asian Ancestry'
    ]
  })
};
</script>

<style scoped>
.ancestry-header.v-card__subtitle {
  color: blue;
  padding-left: 0px;
}
.input-text-field {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
