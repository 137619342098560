<template>
  <v-card-text>
    <v-form v-model="valid" ref="form">
      <v-select
        label="Fertility Specific Insurance"
        :items="fertilityCompanies"
        v-model="value.fertilityCompany"
        :rules="nonNullRule"
        menu-props="auto"
        required
      />
      <v-select
        label="Primary Insurance Company"
        :items="insuranceCompanies"
        v-model="value.company"
        :rules="nonNullRule"
        menu-props="auto"
        required
      />
      <v-container v-if="this.value.company !== 'No Insurance'">
        <TextField
          label="Policy Number or Member Id*"
          v-model="value.memberId"
          :rules="nonNullRule"
          required
        />
        <TextField
          label="Group Number"
          v-model="value.groupNumber"
          :rules="nonNullRule"
          required
        />
        <TextField
          label="Policy Holder Name"
          v-model="value.name"
          :rules="nonNullRule"
          required
        />
        <TextField
          label="Policy Holder SSN"
          v-model="value.ssn"
          type="number"
        />
        <div class="mt-10">
          <DateField label="Policy Holder Date Of Birth*" v-model="value.dob" />
        </div>

        <v-row>
          <v-col cols="12" md="6" class="pr-0 pr-md-4 pl-0">
            <v-card-subtitle class="pr-0 pl-0">
              Front Insurance Image
            </v-card-subtitle>
            <v-file-input
              v-if="!value.frontImage.document"
              v-model="file1"
              @change="uploadImage(file1, true)"
              :rules="fileSizeRule"
              required
            />
            <div v-else class="image-wrapper">
              <v-img
                :src="`data:image/jpg;base64, ${value.frontImage.document}`"
                max-width="300px"
              />
              <v-btn
                class="remove-image-btn"
                fab
                dark
                x-small
                top
                absolute
                color="red"
                @click="removeImage(true)"
              >
                X
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="pr-0 pl-0">
            <v-card-subtitle class="pr-0 pl-0">
              Back Insurance Image
            </v-card-subtitle>
            <v-file-input
              v-if="!value.backImage.document"
              v-model="file2"
              @change="uploadImage"
              :rules="fileSizeRule"
              required
            />
            <div v-else class="image-wrapper">
              <v-img
                :src="`data:image/jpg;base64, ${value.backImage.document}`"
                max-width="300px"
              />
              <v-btn
                class="remove-image-btn"
                fab
                dark
                x-small
                top
                absolute
                color="red"
                @click="removeImage(false)"
              >
                X
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>
<script>
import inputRulesMixins from '../../../mixins/inputRulesMixins.js';
import { toUploadFormat } from '../../../utils/files.js';

const base64transformer = (val) => {
  const result = val.split('base64,');
  if (result.length === 2) {
    return result[1];
  } else {
    return val;
  }
};

export default {
  props: ['valid', 'value'],
  mixins: [inputRulesMixins],
  data: () => ({
    file1: null,
    file2: null,
    insuranceCompanies: [
      'No Insurance',
      'Aetna',
      'Anthem',
      'Blue Cross Blue Shield',
      'Cigna',
      'Optum',
      'United Healthcare',
      'UMR',
      'Other'
    ],
    fertilityCompanies: [
      'None',
      'Progyny',
      'Carrot',
      'Win Fertility',
      'Stork Club',
      'Maven',
      'Other'
    ],
    fileSizeRule: [
      (v) => !!v || 'Input required.',
      (v) => v?.size <= 5242880 || 'Image must be under 5MB',
      (v) => v?.type.includes('image') || 'Unsupported file type.'
    ]
  }),
  watch: {
    valid(val) {
      this.$emit('changeValid', val);
    },
    value(val) {
      this.$refs.form.validate();
      this.$emit('input', val);
    }
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate();
      return valid;
    },
    change() {
      this.$refs.form.validate();
    },
    uploadImage(file, front) {
      try {
        if (file && file.size <= 5242880 && file.type.includes('image')) {
          toUploadFormat(file).then((res) => {
            const key = front ? 'frontImage' : 'backImage';
            this.$emit('input', {
              ...this.value,
              [key]: {
                name: file.name,
                document: base64transformer(res)
              }
            });
          });
        } else {
          this.removeImage(front);
        }
      } catch (error) {
        this.removeImage(front);
      }
    },
    removeImage(frontImage) {
      const key = frontImage ? 'frontImage' : 'backImage';

      frontImage ? (this.file1 = null) : (this.file2 = null);

      this.$emit('input', {
        ...this.value,
        [key]: {
          name: null,
          document: null
        }
      });
    }
  }
};
</script>

<style scoped>
.image-wrapper {
  width: 300px;
  position: relative;
}
@media (max-width: 768px) {
  .image-wrapper {
    width: 100%;
  }
}
.remove-image-btn {
  right: -10px;
}
</style>
