import DateField from '../components/inputs/DateField';
import TextField from '../components/inputs/TextField';
import Select from '../components/inputs/Select';
import Checkbox from '../components/inputs/Checkbox';
import TextArea from '../components/inputs/TextArea';
import RadioGroup from '../components/inputs/RadioGroup';

export default {
  components: { DateField, TextField, Select, Checkbox, TextArea, RadioGroup },
  data: () => ({
    genericInputRules: [(v) => !!v || 'Input required.'],
    genericNumberInputRules: [
      (v) => v !== undefined || 'Input required.',
      (v) => v >= 0 || 'Must be at greater than or equal to 0'
    ],
    greaterThanZeroRules: [
      (v) => v !== undefined || 'Input required.',
      (v) => v > 0 || 'Must be greater than 0'
    ],
    greaterThanZeroOptionalRules: [
      (v) => (v ? v > 0 : null || 'Must be greater than 0')
    ],
    ageRules: [
      (v) => v !== undefined || 'Input required.',
      (v) => v > 18 || 'Must be at least 18 years old'
    ],
    ageOptionalRules: [
      (v) => (v ? v > 18 : null || 'Must be at least 18 years old')
    ],
    nonNullRule: [(v) => !!v || 'Please select an option'],
    titles: ['Miss', 'Mrs.', 'Ms.'],
    races: [
      'American Indian or Alaska Native',
      'Asian',
      'Black or African American',
      'Hispanic or Latino',
      'Native Hawaiian or Other Pcific Islander',
      'White'
    ],
    defaultRadioItems: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    numberNotZeroInputRules: [
      (v) => v !== undefined || 'Input required.',
      (v) => v > 0 || 'Must be greater than 0'
    ],
    feetNumberRules: [
      (v) => v !== undefined || 'Input required.',
      (v) => v > 0 || 'Must be greater than 0',
      (v) => v <= 10 || 'Must be less than or equal to 10'
    ],
    inchesNumberRules: [
      (v) => v !== undefined || 'Input required.',
      (v) => v > 0 || 'Must be greater than 0',
      (v) => v <= 12 || 'Must be less than or equal to 12'
    ]
  })
};
